import React, { Dispatch, SetStateAction } from 'react'
import {
  OnboardingFlowType,
  useCreateFlowChatSessionMutation,
  UserTask,
} from '__generated__/api-types-and-hooks'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { FieldValues, UseFormSetValue } from 'react-hook-form'
import { AppPlusIcon } from 'components/Common/AppSvgIcons/AppPlusIcon'
import { getTenantId } from 'utils/getTenantId'
import { ChatBotFlowUrl } from 'components/AppOnboarding/AppChatbot/AppChatBotFlows/constants'
import redirectTo from 'utils/redirectTo'
import hookForms from 'utils/hookForms'
import moment from 'moment'
import { FORM } from 'appConfig/enums'

interface IAppTaskPopupsProps {
  showWarningPopup: any
  isViewMode: boolean
  task?: UserTask
  setGoalExpectedEndDate: Dispatch<SetStateAction<string>>
  setShowWarningPopup: Dispatch<
    SetStateAction<
      | 'effortPopup'
      | 'moreThanCapacityPopup'
      | 'startDateOutsideGoalTimelinePopup'
      | 'endDateOutsideGoalTimelinePopup'
      | 'none'
    >
  >
  updateTaskData: () => void
  setValue: UseFormSetValue<FieldValues>
  handlCreateTask: () => void
  handleGoalEndDateUpdate: () => void
  goalEndDate: string
}

export const AppTaskPopups: React.FC<IAppTaskPopupsProps> = ({
  showWarningPopup,
  isViewMode,
  task,
  setValue,
  setGoalExpectedEndDate,
  handlCreateTask,
  setShowWarningPopup,
  updateTaskData,
  handleGoalEndDateUpdate,
  goalEndDate,
}) => {
  const { mutate: createFlowChatSessionMutate } = useCreateFlowChatSessionMutation()
  const data = hookForms.getForm(FORM.USER_TASK_FORM)?.getValues()

  const handleCancel = () => {
    setShowWarningPopup('none')
    isViewMode && setValue('startDate', task?.startDate)
    isViewMode && setValue('expectedEndDate', task?.expectedEndDate)
    isViewMode && setValue('goalId', task?.goalId)
    setGoalExpectedEndDate(goalEndDate ?? '')
  }

  const handleCancelEffortPopup = () => {
    setShowWarningPopup('none')
    setValue('estimatedEffortInMinutes', task?.estimatedEffortInMinutes ?? 0)
  }

  const handleProceedAnyway = () => {
    isViewMode && updateTaskData()
    setShowWarningPopup('none')
  }

  const handleMoveAnyway = () => {
    isViewMode ? updateTaskData() : handlCreateTask()
    setGoalExpectedEndDate(goalEndDate ?? '')
    setShowWarningPopup('none')
  }

  return (
    <>
      {showWarningPopup === 'endDateOutsideGoalTimelinePopup' && (
        <AppModalBox
          title={'Heads up!'}
          bgColor="bg-background-transparentBg"
          onClose={handleCancel}
        >
          <div className="my-3 rounded-lg p-5">
            <div className="font-inter font-small">
              Adding this play will change your deadline from
              {` ${moment(data.goalExpectedEndDate).format('dddd, MMMM Do YYYY')}`} to
              {` ${moment(goalEndDate).format('dddd, MMMM Do YYYY')}`}.
            </div>
            <div className="font-inter font-small mt-2">Still want to add it?</div>

            <div className="flex justify-end space-x-4 mt-5">
              <AppButton
                label="Add play and extend deadline"
                size="md"
                onClick={handleGoalEndDateUpdate}
                variant="primary"
              />
            </div>
          </div>
        </AppModalBox>
      )}

      {showWarningPopup === 'moreThanCapacityPopup' && (
        <AppModalBox
          title={'Heads up!'}
          bgColor="bg-background-transparentBg"
          onClose={handleCancel}
        >
          <div className="my-3 rounded-lg p-5">
            <div className="font-inter font-small">
              This will take more effort than the capacity you have available for this goal.
            </div>
            <div className="font-inter font-small mt-2">
              Update the play anyway or extend the goal deadline so you have enough time to complete
              the play?
            </div>

            <div className="mt-3"></div>
            <div className="flex justify-end space-x-4 mt-5">
              <AppButton
                label="Update Anyway"
                size="md"
                onClick={handleMoveAnyway}
                variant="primary"
              />
              <AppButton
                label="Extend Deadline"
                size="md"
                onClick={handleGoalEndDateUpdate}
                variant="success"
              />
            </div>
          </div>
        </AppModalBox>
      )}

      {showWarningPopup === 'startDateOutsideGoalTimelinePopup' && (
        <AppModalBox
          title={'Heads up!'}
          bgColor="bg-background-transparentBg"
          onClose={handleCancel}
        >
          <div className="my-3 rounded-lg p-5">
            <div className="font-inter font-small">
              You’re trying to move a play outside of your deadline.
            </div>
            <div className="font-inter font-small mt-2">
              Want a new plan with a later deadline? Add a new goal in the Goal Center
            </div>

            <div className="flex justify-end space-x-4 mt-5">
              <AppButton
                variant="primary"
                size="lg"
                label="Add a Goal"
                RightIcon={AppPlusIcon}
                onClick={() => {
                  createFlowChatSessionMutate(
                    {
                      input: {
                        flow: OnboardingFlowType.AddGoal,
                      },
                    },
                    {
                      onSuccess: (res) => {
                        const tenantId = getTenantId()
                        redirectTo(
                          `/owner-v2/${tenantId}/onboarding/${
                            ChatBotFlowUrl[OnboardingFlowType.AddGoal]
                          }/${res.createFlowChatSession.id}`
                        )
                      },
                    }
                  )
                }}
              />
            </div>
          </div>
        </AppModalBox>
      )}
      {showWarningPopup === 'effortPopup' && (
        <AppModalBox
          title={'Heads up!'}
          bgColor="bg-background-transparentBg"
          onClose={handleCancelEffortPopup}
        >
          <div className="my-3 rounded-lg p-5">
            <div className="font-inter font-small">
              We suggest that you keep less than 8 hours of effort assigned to a single play. Try
              splitting the play into multiple plays with less effort to make it easier to complete.
            </div>
            <div className="font-inter font-small mt-2">
              If you can’t split it, update it anyway.
            </div>
            <div className="flex justify-end space-x-4 mt-5">
              <AppButton
                label="Update Anyway"
                size="md"
                onClick={handleProceedAnyway}
                variant="primary"
              />
            </div>
          </div>
        </AppModalBox>
      )}
    </>
  )
}
