import styled from 'styled-components'
import tw from 'twin.macro'

export const AppAvatarWrapper = styled.div`
  ${tw`overflow-visible relative leading-none`}
`
// Helper function to get styles based on size and shape
const getSizeAndShapeStyles = (
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl',
  shape: 'Squared' | 'Rounded'
) => {
  const sizes = {
    xs: tw`w-[24px] h-[24px] text-sm`,
    sm: tw`w-[32px] h-[32px] text-base`,
    md: tw`w-[40px] h-[40px] text-xl`,
    lg: tw`w-[48px] h-[48px] text-2xl`,
    xl: tw`w-[64px] h-[64px] text-3xl`,
    '2xl': tw`w-[80px] h-[80px] text-4xl`,
    '3xl': tw`w-[96px] h-[96px] text-5xl`,
  }

  const shapes = {
    Rounded: {
      xs: tw`rounded-[12px]`,
      sm: tw`rounded-[16px]`,
      md: tw`rounded-[20px]`,
      lg: tw`rounded-[24px]`,
      xl: tw`rounded-[32px]`,
      '2xl': tw`rounded-[40px]`,
      '3xl': tw`rounded-[48px]`,
    },
    Squared: {
      xs: tw`rounded-[6px]`,
      sm: tw`rounded-[8px]`,
      md: tw`rounded-[8px]`,
      lg: tw`rounded-[12px]`,
      xl: tw`rounded-[12px]`,
      '2xl': tw`rounded-[16px]`,
      '3xl': tw`rounded-[16px]`,
    },
  }

  // Default to 'md' size and 'Squared' shape if not found
  const selectedSize = sizes[size] || sizes.md
  const selectedShape = (shapes[shape] && shapes[shape][size]) || shapes.Squared.md

  return [selectedSize, selectedShape] as const
}

export const AppStyledAvatarWrapper = styled.div<{
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  shape: 'Squared' | 'Rounded'
  color?: 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange'
}>`
  ${tw`inline-flex items-center justify-center font-inter overflow-hidden z-[1]`}

  img {
    ${tw`w-full h-full object-cover`}
  }

  ${({ color }) => color === 'Grey' && tw`bg-app-white-95 text-app-grey-75`}
  ${({ color }) => color === 'Primary' && tw`bg-app-primary-20 text-app-primary-70`}
  ${({ color }) => color === 'Red' && tw`bg-app-red-25 text-app-red-65`}
  ${({ color }) => color === 'Green' && tw`bg-app-green-25 text-app-green-65`}
  ${({ color }) => color === 'Orange' && tw`bg-app-orange-25 text-app-orange-65`}

  ${({ size, shape }) => getSizeAndShapeStyles(size, shape)}
`
export const AppStyledDotWrapper = styled.div`
  ${tw`absolute bottom-[1px] right-[1px] z-[10]`}
`
