import React from 'react'

function AppCrossIcon({ className, color = '#5E5E5E' }) {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 15L15 5M5 5L15 15"
        stroke={color}
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

AppCrossIcon.defaultProps = {
  className: '',
}
export default AppCrossIcon
