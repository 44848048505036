import React, { FC } from 'react'

import { AppButton } from 'components/Common/AppButton/AppButton.component'
import {
  AppButtonSelectWrapper,
  AppButtonSelectOptionContainer,
} from 'components/Common/AppFormFields/AppButtonSelect/AppButtonSelect.style'

export interface IAppButtonSelectProps {
  value: string
  options: {
    value?: string
    label?: string
    noneOfTheAbove?: boolean
  }[]
  onChange: (input: string) => void
  isDisabled?: boolean
  id?: string
}

export const AppButtonSelect: FC<IAppButtonSelectProps> = ({
  value,
  options,
  onChange,
  isDisabled = false,
}) => {
  const handleSelect = (optionValue: string) => {
    if (isDisabled) return
    onChange(optionValue)
  }

  return (
    <AppButtonSelectWrapper>
      {options.map((option, index) => {
        const isSelected = value === option.value
        return (
          <AppButtonSelectOptionContainer>
            <AppButton
              label={option.label || ''}
              size="md"
              onClick={() => handleSelect(option.value || '')}
              variant={isSelected ? 'primary' : 'secondary'}
            />
          </AppButtonSelectOptionContainer>
        )
      })}
    </AppButtonSelectWrapper>
  )
}
