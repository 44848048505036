import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import { default as ReactDropzone } from 'react-dropzone'

import UploadImage from 'components/Common/SvgIcons/UploadImage'
import { UPLOAD_TYPE } from 'config'
import {
  AppAcceptedFileType,
  AppFileType,
  AppDropFileWrapper,
  AppUploadFileWrapper,
} from 'components/Common/AppDropzone/AppDropzone.style'

interface IAppDropzoneProps {
  handleChange
  isMultiple: boolean
  uploadType: string
  fileExtensions: string[]
  uploadFileLoading: boolean
  isBorderVariantChanged: boolean
}
export const AppDropzone: FC<IAppDropzoneProps> = ({
  handleChange,
  isMultiple,
  uploadType,
  fileExtensions,
  uploadFileLoading,
  isBorderVariantChanged,
}) => {
  return (
    <div className="col-span-6 xs:px-0 sm:px-6 xs:pb-5 sm:pb-0 ">
      <ReactDropzone onDrop={handleChange} multiple={isMultiple} disabled={uploadFileLoading}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />

              <AppDropFileWrapper
                className={`${
                  isBorderVariantChanged
                    ? 'xs:bg-border-mobile sm:bg-border-desktop'
                    : 'bg-border-mobile'
                }   rounded-[10px]`}
              >
                <div className="xs:hidden sm:flex flex-col items-center">
                  <div className="sm:py-2 ">
                    <UploadImage className="fill-primary !w-[47px] !h-[33px]" />
                    <div className=" w-[47px] h-[33px] absolute top-[88px] left-3 opacity-0"></div>
                  </div>
                  <div className="text-center">
                    <label className="text-base font-primary">
                      Drop File{isMultiple ? 's' : ''} Here to Upload
                    </label>
                  </div>
                  <div className="relative flex py-3 items-center w-full mx-auto">
                    <div className="flex-grow mt-1 border-t border-secondary-text"></div>
                    <span className="flex-shrink mx-2 text-gray-400 text-silver-darker font-medium text-base font-primary">
                      or
                    </span>
                    <div className="flex-grow mt-1 border-t border-secondary-text"></div>
                  </div>
                </div>
                <div className="xs:py-6 sm:py-0 sm:px-4">
                  <AppUploadFileWrapper>
                    <label
                      className={`text-primary hover:text-primary-brand active:text-primary-active focus:text-primary font-primary leading-snug relative w-full flex justify-center text-base font-semibold cursor-pointer`}
                    >
                      Click to upload
                    </label>
                  </AppUploadFileWrapper>
                  {uploadType && (
                    <AppAcceptedFileType hasPadding={true}>
                      Accepted File Types:
                    </AppAcceptedFileType>
                  )}
                  {uploadType === UPLOAD_TYPE.IMAGE ? (
                    <>
                      <AppAcceptedFileType hasPadding={false}>
                        {!isEmpty(fileExtensions) ? (
                          <label>{fileExtensions.join(', ')} </label>
                        ) : (
                          'Image files such as .JPG, .JPEG, .GIF, .PNG .SVG'
                        )}
                      </AppAcceptedFileType>
                    </>
                  ) : uploadType === UPLOAD_TYPE.DOCUMENT ? (
                    <AppAcceptedFileType hasPadding={false}>
                      {!isEmpty(fileExtensions) ? (
                        <label>{fileExtensions.join(', ')} </label>
                      ) : (
                        'files such as .DOC, .DOCX, .PDF'
                      )}
                    </AppAcceptedFileType>
                  ) : (
                    <>
                      <AppAcceptedFileType hasPadding={true}>
                        Accepted File Types:
                        <br />
                        Microsoft Office files such as .DOC, .DOCX, .XLS, PPTX
                        <br />
                        Adobe .PDF
                        <br />
                        Image files such as .JPG, .JPEG, .GIF, .PNG
                      </AppAcceptedFileType>
                      <AppFileType>
                        .DOC, .DOCX, .PDF, or shared links from
                        <br />
                        Google Drive or Dropbox.
                      </AppFileType>
                    </>
                  )}
                </div>
              </AppDropFileWrapper>
            </div>
          </section>
        )}
      </ReactDropzone>
      <div className="text-xs text-left flex flex-col gap-3 mx-auto xs:py-5 xs:px-5 sm:pt-8 lg:pl-8 lg:pr-20">
        <p>Tip:</p>
        <p>
          If your file is in Dropbox online, Google Drive, or Microsoft OneDrive, you will need to
          download it to your local computer or phone in order to upload it to GoTackle.
        </p>
      </div>
    </div>
  )
}
