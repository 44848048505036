import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import React from 'react'
import showdown from 'showdown'
import backgroundImage from 'assets/images/pdf-background.png'
import Avatar from 'appAssets/appImages/Avatar.png'

interface StyleConfig {
  font: string
  fontSize: number
  textColor: [number, number, number]
  margin: number
  lineHeight: number
}

interface PdfOptions {
  headerImage?: string
  styles?: {
    title?: Partial<StyleConfig>
    description?: Partial<StyleConfig>
    content?: Partial<StyleConfig>
    links?: Partial<StyleConfig>
  }
}

const useDownloadPDF = () => {
  const defaultStyles: Record<'title' | 'description' | 'content' | 'links', StyleConfig> = {
    title: {
      font: 'Inter',
      fontSize: 16,
      textColor: [25, 33, 61],
      margin: 20,
      lineHeight: 1.21,
    },
    description: {
      font: 'Inter',
      fontSize: 14,
      textColor: [63, 63, 70], // #3F3F46
      margin: 20,
      lineHeight: 1.21, // 16.94px / 14px = 1.21
    },
    content: {
      font: 'helvetica',
      fontSize: 12,
      textColor: [71, 84, 103],
      margin: 20,
      lineHeight: 1.5,
    },
    links: {
      font: 'helvetica',
      fontSize: 12,
      textColor: [0, 0, 255],
      margin: 20,
      lineHeight: 1.5,
    },
  }

  const convertMarkdownToHtml = (markdown: string) => {
    const converter = new showdown.Converter({
      simplifiedAutoLink: true,
      strikethrough: true,
      tables: true,
    })
    return converter.makeHtml(markdown)
  }

  const downloadPdf = async (
    contentRef: React.RefObject<HTMLDivElement> | null = null,
    filename = 'download.pdf',
    title?: string,
    description?: string,
    text?: string,
    options: PdfOptions = {}
  ) => {
    try {
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      })

      const pageWidth = pdf.internal.pageSize.width
      const pageHeight = pdf.internal.pageSize.height

      // Merge default styles with provided styles
      const mergedStyles = {
        title: { ...defaultStyles.title, ...(options.styles?.title || {}) },
        description: { ...defaultStyles.description, ...(options.styles?.description || {}) },
        content: { ...defaultStyles.content, ...(options.styles?.content || {}) },
        links: { ...defaultStyles.links, ...(options.styles?.links || {}) },
      }

      if (text) {
        // Add header background image
        const headerHeight = 40
        pdf.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, headerHeight)

        // Add creation date and website URL
        pdf.setFontSize(8)
        pdf.setTextColor(255, 255, 255) // White color
        pdf.text('www.gotackle.app', 25, 25)

        const currentDate = new Date()
        const formattedDate = `Created: ${
          currentDate.getMonth() + 1
        }/${currentDate.getDate()}/${currentDate.getFullYear()}`
        pdf.text(formattedDate, 25, 30)

        let yPosition = headerHeight + 10

        // Process title
        if (title) {
          pdf.setFontSize(mergedStyles.title.fontSize)
          pdf.setTextColor(...mergedStyles.title.textColor)
          pdf.setFont(mergedStyles.title.font, 'medium')
          const splitTitle = pdf.splitTextToSize(title, pageWidth - mergedStyles.title.margin * 2)
          pdf.text(splitTitle, mergedStyles.title.margin, yPosition)
          yPosition += splitTitle.length * 6 // Each line takes 6mm
        }
        yPosition += 5
        // Process description
        if (description) {
          pdf.setFontSize(mergedStyles.description.fontSize)
          pdf.setTextColor(...mergedStyles.description.textColor)
          pdf.setFont(mergedStyles.description.font, 'medium')
          const splitDescription = pdf.splitTextToSize(
            description,
            pageWidth - mergedStyles.description.margin * 2
          )
          pdf.text(splitDescription, mergedStyles.description.margin, yPosition)
          yPosition += splitDescription.length * 5 // Each line takes 5mm
        }
        yPosition += 5

        // Convert markdown to HTML and extract links
        const htmlContent = convertMarkdownToHtml(text)
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = htmlContent

        const links: { text: string; url: string; startIndex: number; endIndex: number }[] = []
        let plainText = ''

        // Convert HTML to plain text while preserving link positions
        const processNode = (node: Node) => {
          if (node.nodeType === Node.TEXT_NODE) {
            plainText += node.textContent
          } else if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as Element
            if (element.tagName === 'A') {
              const startIndex = plainText.length
              plainText += element.textContent
              links.push({
                text: element.textContent || '',
                url: (element as HTMLAnchorElement).href,
                startIndex,
                endIndex: plainText.length,
              })
            } else {
              Array.from(node.childNodes).forEach(processNode)
            }
          }
        }

        Array.from(tempDiv.childNodes).forEach(processNode)

        // Process main content
        pdf.setFontSize(mergedStyles.content.fontSize)
        pdf.setTextColor(...mergedStyles.content.textColor)
        pdf.setFont(mergedStyles.content.font, 'normal')

        const footerHeight = 30 // Height reserved for footer
        const lineHeight = mergedStyles.content.fontSize * 0.5
        const contentStartY = headerHeight + 10 // Starting Y position after header

        const splitText = pdf.splitTextToSize(
          plainText,
          pageWidth - mergedStyles.content.margin * 2
        )

        // Calculate text positions for links
        let currentLineStart = 0
        let currentPage = 1
        let linesOnCurrentPage = 0

        // Add text and create clickable links
        splitText.forEach((line: string, index: number) => {
          const currentY = yPosition + linesOnCurrentPage * lineHeight

          // Check if we need a new page
          if (currentY > pageHeight - footerHeight) {
            // Add footer to current page
            addFooter(pdf, pageWidth, pageHeight, currentPage)

            // Add new page
            pdf.addPage()
            currentPage++

            // Reset positions for new page
            yPosition = contentStartY
            linesOnCurrentPage = 0

            // Add header background to new page
            pdf.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, headerHeight)

            // Add website URL and creation date on new page
            pdf.setFontSize(8)
            pdf.setTextColor(255, 255, 255)
            pdf.text('www.gotackle.app', 25, 25)
            const currentDate = new Date()
            const formattedDate = `Created: ${
              currentDate.getMonth() + 1
            }/${currentDate.getDate()}/${currentDate.getFullYear()}`
            pdf.text(formattedDate, 25, 30)

            // Reset text settings for content
            pdf.setFontSize(mergedStyles.content.fontSize)
            pdf.setTextColor(...mergedStyles.content.textColor)
            pdf.setFont(mergedStyles.content.font, 'normal')
          }

          pdf.text(line, mergedStyles.content.margin, yPosition + linesOnCurrentPage * lineHeight)

          // Check for links in this line
          links.forEach((link) => {
            const linkStart = link.startIndex - currentLineStart
            const linkEnd = link.endIndex - currentLineStart

            if (linkStart >= 0 && linkStart < line.length) {
              const textWidth = pdf.getTextWidth(line.substring(0, linkStart))
              const linkWidth = pdf.getTextWidth(line.substring(linkStart, linkEnd))

              pdf.link(
                mergedStyles.content.margin + textWidth,
                yPosition + linesOnCurrentPage * lineHeight - mergedStyles.content.fontSize * 0.3,
                linkWidth,
                mergedStyles.content.fontSize,
                { url: link.url }
              )
            }
          })

          currentLineStart += line.length
          linesOnCurrentPage++
        })

        yPosition += linesOnCurrentPage * lineHeight + 15

        // Add links section with pagination check
        if (links.length > 0) {
          // Check if links section needs a new page
          if (yPosition > pageHeight - footerHeight - 50) {
            addFooter(pdf, pageWidth, pageHeight, currentPage)
            pdf.addPage()
            currentPage++
            yPosition = contentStartY
            pdf.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, headerHeight)

            // Add website URL and creation date on new page
            pdf.setFontSize(8)
            pdf.setTextColor(255, 255, 255)
            pdf.text('www.gotackle.app', 25, 25)
            const currentDate = new Date()
            const formattedDate = `Created: ${
              currentDate.getMonth() + 1
            }/${currentDate.getDate()}/${currentDate.getFullYear()}`
            pdf.text(formattedDate, 25, 30)
          }

          pdf.setFont(mergedStyles.links.font, 'bold')
          pdf.text('Sources:', mergedStyles.links.margin, yPosition)
          yPosition += 10

          pdf.setFont(mergedStyles.links.font, 'normal')
          pdf.setTextColor(...mergedStyles.links.textColor)

          links.forEach((link, index) => {
            const linkText = `${index + 1}. ${link.text}`
            const splitLink = pdf.splitTextToSize(
              linkText,
              pageWidth - mergedStyles.links.margin * 2
            )

            // Check if we need a new page for this link
            if (
              yPosition + splitLink.length * mergedStyles.links.fontSize * 0.5 >
              pageHeight - footerHeight
            ) {
              addFooter(pdf, pageWidth, pageHeight, currentPage)
              pdf.addPage()
              currentPage++
              yPosition = contentStartY
              pdf.addImage(backgroundImage, 'PNG', 0, 0, pageWidth, headerHeight)

              // Add website URL and creation date on new page
              pdf.setFontSize(8)
              pdf.setTextColor(255, 255, 255)
              pdf.text('www.gotackle.app', 25, 25)
              const currentDate = new Date()
              const formattedDate = `Created: ${
                currentDate.getMonth() + 1
              }/${currentDate.getDate()}/${currentDate.getFullYear()}`
              pdf.text(formattedDate, 25, 30)

              // Reset text settings for links
              pdf.setFont(mergedStyles.links.font, 'normal')
              pdf.setTextColor(...mergedStyles.links.textColor)
            }

            pdf.text(splitLink, mergedStyles.links.margin, yPosition)

            // Make the entire text clickable
            const linkWidth = pdf.getTextWidth(linkText)
            pdf.link(
              mergedStyles.links.margin,
              yPosition - mergedStyles.links.fontSize * 0.3,
              linkWidth,
              mergedStyles.links.fontSize,
              { url: link.url }
            )

            yPosition += splitLink.length * mergedStyles.links.fontSize * 0.5 + 5
          })
        }

        // Add footer to the last page
        addFooter(pdf, pageWidth, pageHeight, currentPage)

        pdf.save(filename)
      } else if (contentRef?.current) {
        // If contentRef is provided, generate a PDF from the content
        const element = contentRef.current
        const canvas = await html2canvas(element, {
          scale: 2,
          useCORS: true,
          allowTaint: true,
        })

        const imgData = canvas.toDataURL('image/png')
        const imgWidth = 190 // A4 width
        const imgHeight = (canvas.height * imgWidth) / canvas.width

        let heightLeft = imgHeight
        let position = 10

        // Add title to the first page if provided
        if (title) {
          const marginTop = 20
          pdf.setFontSize(20)
          pdf.text(title, 10, marginTop)
          position = marginTop + 10
        }

        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
        heightLeft -= pageHeight

        while (heightLeft > 0) {
          position = heightLeft - imgHeight
          pdf.addPage()
          pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight)
          heightLeft -= pageHeight
        }

        // Add footer with divider
        const footerY = pageHeight - 20
        pdf.setDrawColor(229, 231, 235)
        pdf.line(20, footerY - 10, pageWidth - 20, footerY - 10)

        // Add Avatar image
        const avatarSize = 8 // 8mm diameter
        pdf.addImage(Avatar, 'PNG', 20, footerY - 4, avatarSize, avatarSize)

        // Add "Powered by Coach Logic" text
        pdf.setFontSize(8)
        pdf.setTextColor(128, 128, 128) // Gray color for "Powered by"
        pdf.setFont('helvetica', 'normal')
        pdf.text('Powered by', 20 + avatarSize + 2, footerY - 1)

        pdf.setTextColor(33, 33, 33) // Darker color for "Coach Logic"
        pdf.setFont('helvetica', 'bold')
        pdf.setFontSize(9)
        pdf.text('Coach Logic', 20 + avatarSize + 2, footerY + 3)

        // Add page number
        pdf.setFont('helvetica', 'normal')
        pdf.setTextColor(128, 128, 128)
        pdf.text(`Page ${pdf.getNumberOfPages()}`, pageWidth - 30, footerY, { align: 'right' })

        pdf.save(filename)
      }
    } catch (error) {
      console.error('Failed to download PDF:', error)
    }
  }

  // Helper function to add footer
  const addFooter = (pdf: jsPDF, pageWidth: number, pageHeight: number, pageNumber: number) => {
    const footerY = pageHeight - 20

    // Add footer divider
    pdf.setDrawColor(229, 231, 235)
    pdf.line(20, footerY - 10, pageWidth - 20, footerY - 10)

    // Add Avatar image
    const avatarSize = 8
    pdf.addImage(Avatar, 'PNG', 20, footerY - 4, avatarSize, avatarSize)

    // Add "Powered by Coach Logic" text
    pdf.setFontSize(8)
    pdf.setTextColor(128, 128, 128)
    pdf.setFont('helvetica', 'normal')
    pdf.text('Powered by', 20 + avatarSize + 2, footerY - 1)

    pdf.setTextColor(33, 33, 33)
    pdf.setFont('helvetica', 'bold')
    pdf.setFontSize(9)
    pdf.text('Coach Logic', 20 + avatarSize + 2, footerY + 3)

    // Add page number
    pdf.setFont('helvetica', 'normal')
    pdf.setTextColor(128, 128, 128)
    pdf.text(`Page ${pageNumber}`, pageWidth - 30, footerY, { align: 'right' })
  }

  return downloadPdf
}

export default useDownloadPDF
