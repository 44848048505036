import React from 'react'

export const AppQuoteIcon = () => {
  return (
    <svg
      width="272"
      height="115"
      viewBox="0 0 272 115"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3814_5851)">
        <rect width="272" height="115" rx="10" fill="url(#paint0_linear_3814_5851)" />
        <ellipse
          cx="81.5"
          cy="93.5"
          rx="92.5"
          ry="62.5"
          fill="url(#paint1_linear_3814_5851)"
          fill-opacity="0.3"
        />
        <path
          d="M241.5 93.5C241.5 110.464 227.569 125.961 204.728 137.246C181.918 148.515 150.372 155.5 115.5 155.5C80.628 155.5 49.0819 148.515 26.2725 137.246C3.43138 125.961 -10.5 110.464 -10.5 93.5C-10.5 76.5357 3.43138 61.0392 26.2725 49.7541C49.0819 38.4846 80.628 31.5 115.5 31.5C150.372 31.5 181.918 38.4846 204.728 49.7541C227.569 61.0392 241.5 76.5357 241.5 93.5Z"
          fill="url(#paint2_linear_3814_5851)"
          fill-opacity="0.3"
          stroke="url(#paint3_linear_3814_5851)"
        />
        <g filter="url(#filter0_di_3814_5851)">
          <g clip-path="url(#clip1_3814_5851)">
            <rect
              x="87"
              y="31.1611"
              width="95.5"
              height="95.5"
              rx="14.1606"
              transform="rotate(-9.13467 87 31.1611)"
              fill="url(#paint4_linear_3814_5851)"
            />
            <g filter="url(#filter1_d_3814_5851)">
              <rect
                x="84.5393"
                y="31.5569"
                width="100.645"
                height="25.017"
                transform="rotate(-9.13467 84.5393 31.5569)"
                fill="url(#paint5_linear_3814_5851)"
              />
              <rect
                x="101.141"
                y="18.3702"
                width="8.57761"
                height="21.444"
                rx="4.2888"
                transform="rotate(-9.13467 101.141 18.3702)"
                fill="#5A41F7"
              />
              <rect
                x="150.621"
                y="10.4142"
                width="8.57761"
                height="21.444"
                rx="4.2888"
                transform="rotate(-9.13467 150.621 10.4142)"
                fill="#5A41F7"
              />
            </g>
            <path
              d="M144.995 61.7069L150.227 94.2444L143.347 95.3506L139.165 69.3428L138.975 69.3735L132.275 75.2425L131.294 69.1417L138.528 62.7466L144.995 61.7069Z"
              fill="url(#paint6_linear_3814_5851)"
            />
          </g>
          <rect
            x="85.5846"
            y="30.1379"
            width="97.97"
            height="97.97"
            rx="15.3956"
            transform="rotate(-9.13467 85.5846 30.1379)"
            stroke="url(#paint7_linear_3814_5851)"
            stroke-width="2.47"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_di_3814_5851"
          x="52.596"
          y="-14.4041"
          width="178.258"
          height="178.258"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="17" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.64 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3814_5851" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3814_5851"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="0.944037"
            operator="erode"
            in="SourceAlpha"
            result="effect2_innerShadow_3814_5851"
          />
          <feOffset dy="2.36009" />
          <feGaussianBlur stdDeviation="0.944037" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.44 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3814_5851" />
        </filter>
        <filter
          id="filter1_d_3814_5851"
          x="82.6513"
          y="8.26172"
          width="107.117"
          height="51.7711"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.88807" />
          <feGaussianBlur stdDeviation="0.944037" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.797494 0 0 0 0 0.797494 0 0 0 0 0.797494 0 0 0 1 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3814_5851" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3814_5851"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_3814_5851"
          x1="136"
          y1="0"
          x2="136"
          y2="115"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#493B9F" />
          <stop offset="1" stop-color="#6F59F2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3814_5851"
          x1="49"
          y1="41"
          x2="174"
          y2="127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F59F4" />
          <stop offset="0.51" stop-color="#5746C0" />
          <stop offset="1" stop-color="#41348E" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3814_5851"
          x1="71.0541"
          y1="41"
          x2="204.649"
          y2="166.698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F59F4" />
          <stop offset="0.51" stop-color="#5746C0" />
          <stop offset="1" stop-color="#41348E" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3814_5851"
          x1="115.5"
          y1="31"
          x2="115.5"
          y2="156"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#6F59F2" />
          <stop offset="1" stop-color="#423592" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3814_5851"
          x1="134.75"
          y1="100.106"
          x2="134.75"
          y2="142.497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFFEFE" />
          <stop offset="1" stop-color="#F0F0F0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3814_5851"
          x1="84.5393"
          y1="44.0654"
          x2="185.185"
          y2="44.0654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#A79AF9" />
          <stop offset="1" stop-color="#705AF5" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3814_5851"
          x1="134.716"
          y1="32.4839"
          x2="145.781"
          y2="101.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.33" stop-color="#705AF8" />
          <stop offset="0.94" stop-color="#A798FF" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3814_5851"
          x1="134.75"
          y1="31.1611"
          x2="134.75"
          y2="126.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FAF9FF" />
          <stop offset="1" stop-color="#7762F5" />
        </linearGradient>
        <clipPath id="clip0_3814_5851">
          <rect width="272" height="115" rx="10" fill="white" />
        </clipPath>
        <clipPath id="clip1_3814_5851">
          <rect
            x="87"
            y="31.1611"
            width="95.5"
            height="95.5"
            rx="14.1606"
            transform="rotate(-9.13467 87 31.1611)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
