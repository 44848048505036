import React from 'react'

import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppCheckIcon } from 'components/Common/AppSvgIcons/AppCheckIcon'
import AppCrossIcon from 'components/Common/AppSvgIcons/AppModalCrossIcon'
import { UserTaskItemStatus } from '__generated__/api-types-and-hooks'
import { useParams } from 'react-router-dom'
interface AppGoalSuggestedByBoxProps {
  imageUrl?: string
  onAccept: () => void
  onReject: () => void
  userInitial: string
  status: UserTaskItemStatus
}

export const AppGoalSuggestedByBox: React.FC<AppGoalSuggestedByBoxProps> = ({
  imageUrl,
  userInitial,
  onAccept,
  onReject,
  status,
}) => {
  const { clientId } = useParams()
  const isAcceptedOrRejected = status !== UserTaskItemStatus.Pending
  const checkIconColor = isAcceptedOrRejected ? '#61BA47' : '#999999'
  const crossIconColor = status === UserTaskItemStatus.Archived ? '#D0021B' : '#999999'
  const acceptButtonVariant =
    isAcceptedOrRejected && status !== UserTaskItemStatus.Archived ? 'success' : 'secondary'
  const rejectButtonVariant = status === UserTaskItemStatus.Archived ? 'alert' : 'secondary'
  return (
    <div className="flex items-center gap-2 mt-2">
      <AppButton
        label="Suggested By"
        size="sm"
        variant="secondary"
        RightComponent={
          (
            <AppAvatar
              size="sm"
              color="Primary"
              type={imageUrl ? 'image' : 'text'}
              shape="Rounded"
              src={imageUrl}
              text={userInitial}
            />
          ) as any
        }
      />
      <div className="flex gap-1">
        <AppButton
          size="sm"
          disabled={isAcceptedOrRejected || clientId}
          variant={acceptButtonVariant}
          LeftIcon={(props) => <AppCheckIcon {...props} color={checkIconColor} />}
          onClick={(e) => {
            e?.preventDefault()
            e?.stopPropagation()
            onAccept()
          }}
        />
        <AppButton
          size="sm"
          disabled={isAcceptedOrRejected || clientId}
          variant={rejectButtonVariant}
          LeftIcon={(props) => <AppCrossIcon {...props} color={crossIconColor} />}
          onClick={(e) => {
            e?.preventDefault()
            e?.stopPropagation()
            onReject()
          }}
        />
      </div>
    </div>
  )
}
