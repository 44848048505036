import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { format } from 'date-fns'
import { AppCalendarWrapper } from 'components/Common/AppFormFields/AppPicker/AppPicker.style'
import moment from 'moment-timezone'
import { AppChevronDownIcon } from 'components/Common/AppSvgIcons/AppChevronDownIcon'

interface IPickerProps {
  type: 'date' | 'range' | 'year'
  value: string | { start: string; end: string }
  onChange: (value: string | { start: string; end: string }) => void
  isdisable?: boolean
  minDate?: string
}

export const AppPicker: React.FC<IPickerProps> = ({
  type,
  onChange,
  value: propValue,
  isdisable,
  minDate,
}) => {
  const [value, setValue] = useState<Date | [Date, Date] | null>()
  const [view, setView] = useState<'month' | 'year' | 'decade'>(
    type === 'year' ? 'decade' : 'month'
  )
  // Synchronize state with propValue
  useEffect(() => {
    if (type === 'date' && typeof propValue === 'string') {
      const localDate = moment(propValue).local().toDate()
      setValue(localDate)
    } else if (
      type === 'range' &&
      typeof propValue === 'object' &&
      propValue.start &&
      propValue.end
    ) {
      const start = moment(propValue.start).local().toDate()
      const end = moment(propValue.end).local().toDate()
      setValue([start, end])
    } else if (type === 'year' && typeof propValue === 'string') {
      const yearDate = moment(`${propValue}-01-01`).local().toDate()
      setValue(yearDate)
    } else {
      setValue(null)
    }
  }, [propValue, type])

  const handleChange = (newValue) => {
    setValue(newValue)

    if (type === 'date' && newValue instanceof Date) {
      // Format date using moment in local timezone
      const localDate = moment(newValue).local().format('MM/DD/YYYY')
      onChange(localDate)
    }

    if (type === 'range' && Array.isArray(newValue)) {
      const [start, end] = newValue
      if (start && end) {
        onChange({
          start: moment(start).local().format('MM/DD/YYYY'),
          end: moment(end).local().format('MM/DD/YYYY'),
        })
      }
    }
  }

  const handleYearClick = (date: Date) => {
    if (type === 'year') {
      const year = date.getFullYear().toString()
      onChange(year)
      setValue(date)
    }
  }
  const handleMonthClick = () => {
    setView('month')
  }

  const handleActiveStartDateChange = ({ activeStartDate, view: newView }) => {
    if (type !== 'year') {
      setView(newView === 'month' ? 'year' : newView)
    }
  }

  return (
    <AppCalendarWrapper style={isdisable ? { pointerEvents: 'none', opacity: 0.5 } : {}}>
      <Calendar
        onChange={handleChange}
        value={value}
        selectRange={type === 'range'}
        view={view}
        onClickYear={handleYearClick}
        onClickMonth={handleMonthClick}
        onActiveStartDateChange={handleActiveStartDateChange}
        navigationLabel={({ date }) => {
          // Disable navigation click if type is 'year'
          return view === 'month' ? (
            <span
              className="flex flex-row justify-center gap-1 items-center"
              onClick={() => type !== 'year' && setView('year')}
            >
              {format(date, 'MMMM yyyy')}
              <AppChevronDownIcon color="black" />
            </span>
          ) : (
            <span className="disabled">{date.getFullYear()}</span>
          )
        }}
        tileDisabled={({ view }) => type === 'year' && view !== 'decade'}
        minDate={minDate ? new Date(minDate) : undefined}
      />
    </AppCalendarWrapper>
  )
}
