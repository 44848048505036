import React from 'react'

import { ROLES } from 'config'
import { navigateTo } from 'utils/helper'
import { BreadCrumbWrapper } from 'components/Clients/style'
import { AppBackButtonArrowIcon } from 'components/Common/AppSvgIcons/AppBackButtonArrowIcon'

const BreadCrumbs = () => {
  const navigateToClientsHandler = () => {
    if (window.location.href.includes(ROLES.BSO_ADVISOR)) {
      navigateTo(ROLES.BSO_ADVISOR, 'clients')
    } else {
      navigateTo(ROLES.BSO_ADMIN, 'clients')
    }
  }

  return (
    <>
      <BreadCrumbWrapper>
        <div
          className="flex items-center space-x-4 cursor-pointer"
          onClick={() => navigateToClientsHandler()}
        >
          <AppBackButtonArrowIcon />{' '}
          <span className="text-primary text-base font-semibold">All Clients</span>
        </div>
      </BreadCrumbWrapper>
    </>
  )
}

export default BreadCrumbs
