import React from 'react'

export const AppPlayTutorialIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.2583 8.72682C12.4726 8.84589 12.4726 9.15411 12.2583 9.27317L7.58926 11.8671C7.38097 11.9828 7.125 11.8322 7.125 11.5939V6.4061C7.125 6.16782 7.38097 6.01721 7.58926 6.13292L12.2583 8.72682Z"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
