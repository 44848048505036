import React from 'react'
import { AppDashboardGridItemWrapper } from '../style'
import useNavigateTo from 'hooks/useNavigateTo'
import { AppFC, IUpcomingEvent } from 'types'

import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'
import AppDashboardGridItemTitle from '../AppDashboardGridItemTitle'
import { AppEventCard } from '../AppEventCard'
import ActionCard from 'components/Common/ActionCard'

export interface IUpcomingEventsProps {
  events: IUpcomingEvent[]
  noEventsMessage: string
  loading?: boolean
}

/** Displays event cards featuring details about a few most recent events for the user */
const AppDashboardUpcomingEvents: AppFC<IUpcomingEventsProps> = ({
  events = [],
  className = '',
  noEventsMessage = '',
  loading = false,
}) => {
  const navigate = useNavigateTo('calendar')
  const userRole = getRoleFromPath()

  const noEventsScheduled = !loading && events.length === 0

  return (
    <AppDashboardGridItemWrapper>
      <div id="admin-upcoming-events-section" className={`space-y-5 ${className}`}>
        <AppDashboardGridItemTitle>Upcoming Appointments</AppDashboardGridItemTitle>
        {userRole?.includes(ROLES.BUSINESS_OWNER) ? (
          <div>
            {noEventsScheduled && (
              <p className="italic text-black-light text-lg sm:text-xl -mt-2">
                No {userRole === ROLES.V2 ? 'appointments yet!' : 'appointments today'}
              </p>
            )}
            <div className="space-y-4">
              {noEventsScheduled ? (
                <ActionCard onClick={() => navigate()}>
                  <div>
                    <p className="leading-6 font-semibold text-lg">{noEventsMessage}</p>
                  </div>
                </ActionCard>
              ) : (
                events.map((event, idx) => {
                  return (
                    <div className="md:max-w-[334px] lg:max-w-[400px] lg:min-w-[400px]">
                      <AppEventCard
                        key={`event-card-${idx + 1}`}
                        description={event.description}
                        startDate={event.date}
                        endDate={event.endDate ?? ''}
                        eventId={event.eventId}
                      />
                    </div>
                  )
                })
              )}
            </div>
          </div>
        ) : (
          <div className="h-[350px] bg-app-white-100 flex items-center justify-center font-medium text-sm">
            You cannot view this information
          </div>
        )}
      </div>
    </AppDashboardGridItemWrapper>
  )
}

export default AppDashboardUpcomingEvents
