import React, { FC } from 'react'
import {
  AppCardWrapper,
  AppCardFooter,
  FooterContentWrapper,
  TaskTextWrapper,
  MessageIcon,
  TaskInstruction,
  TaskName,
  CardHeader,
  BadgesContainer,
} from 'components/AppKanbanBoard/AppCard/AppCard.style'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { getInitials } from 'components/Chat/helper'
import { getColorForGoalCategory, getColorForGoalPriority } from 'appUtils'
import Avatar from 'appAssets/appImages/Avatar.png'
import appMessage from 'appAssets/appImages/appMessage.png'
import { categoryType, taskPriorityStatus } from 'appConfig/data'
import { AppBinIcon } from 'components/Common/AppSvgIcons/AppBinIcon'
import { UserTask } from '__generated__/api-types-and-hooks'

interface IAppTaskCard {
  task: UserTask
  userId: string
  userAvatar: string
  userFirstName: string
  userLastName: string
  onTrashClick?: () => void
}

export const AppTaskCard: FC<IAppTaskCard> = ({
  task,
  userId,
  userAvatar,
  userFirstName,
  userLastName,
  onTrashClick,
}) => {
  return (
    <AppCardWrapper>
      <CardHeader>
        <TaskTextWrapper>
          <TaskName>{task.name}</TaskName>
          <TaskInstruction>{task.instructionForTask}</TaskInstruction>
        </TaskTextWrapper>
        {onTrashClick && (
          <div className="flex items-end gap-1">
            <MessageIcon src={appMessage} alt="messagebox" />

            <div
              onClick={(event) => {
                event.stopPropagation()
                onTrashClick()
              }}
            >
              <AppBinIcon />
            </div>
          </div>
        )}
      </CardHeader>

      <AppCardFooter>
        <FooterContentWrapper>
          <BadgesContainer>
            {task.category && (
              <AppBadge
                size="xs"
                type="Filled"
                color={getColorForGoalCategory(task.category)}
                label={categoryType[task.category]}
              />
            )}
            {task.taskPriority && (
              <AppBadge
                size="xs"
                type="Filled"
                color={getColorForGoalPriority(task.taskPriority)}
                label={taskPriorityStatus[task.taskPriority]}
              />
            )}
            {task.isOverDue && <AppBadge size="xs" type="Filled" color={'Red'} label={'Overdue'} />}
          </BadgesContainer>
          {task.assigneeId && task.assigneeId !== userId ? (
            <AppAvatar size={'sm'} type={'image'} shape={'Rounded'} src={Avatar} />
          ) : (
            <AppAvatar
              size="sm"
              type="mixed"
              shape="Rounded"
              src={userAvatar}
              text={getInitials({
                firstName: userFirstName ?? '',
                lastName: userLastName ?? '',
              })}
            />
          )}
        </FooterContentWrapper>
      </AppCardFooter>
    </AppCardWrapper>
  )
}
