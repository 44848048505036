import React from 'react'

const ClipboardPin = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="17" height="17" fill="url(#pattern0_674_2493)" />
      <defs>
        <pattern
          id="pattern0_674_2493"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_674_2493" transform="scale(0.0111111)" />
        </pattern>
        <image
          id="image0_674_2493"
          width="90"
          height="90"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADFElEQVR4nO2cPYxMURTHf4PMI1YiJCZRkQiiERGNhEShEoUODYWeaIhG5aOwQUR8FUpam92hIkolYgXZZEN8Jb7WZ1h25MadZDPZXe/Nu/e+M/eeX3Lqmfeb/5z77rtnBhRFURRFURRFUZRKyYB+4APwEjgO1Kt9S3FyHmh11KD9ABRHbAUmphBt6iYw19ULpcxC4MU0klW2Q679R7LKdsCOnJJVdgmWAG8LijZ1S3t2MQa7kKyyC7KvhGSVnZNlwJgD0dpGZmAWcMeRZJU9AwcdS1bZU7Aa+O5JtLYRyxzgnkfJKttyNIDk5GWvA34FFJ1kG8mAB4ElJym7vyLJScneBPypWLSpZsyHB33AiADJ7TpBpBwTIHdymTPIKGkAwwIEt+sdEdMQJNssylHTECD7gqSxhXn2WOkMcBd4BXyzb/Qj8AQYAI4AG7qQ/agCweYatiGEVcBl4HPBi3gGHLB3FxJlXwcWI4BF9iv1u+QFvQH2AjUhst8DOxHC5hxzFK2CNZAzQT5lDwFLEcJujw94RoAVOU+/Hzp8XbOW7M/5rQrGxQAbg5UBk20W7uUIpDbNMGHLYZm+vcZzsn8Ah4DZCKbXZd8H1tIj9KLsceCkpM1HjLKHu9goiUK67AngEjCfCJAqexTYQmTUbHKk3PqZB/ULiJSaoGRHT80+ZPKdbJGbjBhlP5XydC2FNjIk7flEzMneU/VFpiL7dYHDgyRkX/Eo25zUKPyb8ml6FG2OxZIn8yy5XetTNl23R1StAHWYRMkCJbldN0iQLIfkpuMF8jGJUc/RLtozxy43NZ9IiCxnkjMP99lmviQJ6gWS3ImLZH8lAbpJcidlk/2cyCmTZJfJvk3EuJRcVvYpIsVFu3DZRrYTIT6SXCbZY3Y+Oyp8JrnbA18zDxgVISXnlT2ecwK1ZwjRLrppI2eJiCqSnCfZo/bPBaNAguSpZP8ENhIJkiRPlm0Wv11EgkTJUXJOJYf5y7P2jzE1yZ5Fm994qOQAXNWeHIY+K9sk+wtwWhc+v+gQoaIoiqIoiqIoiqIoCOMvEw1NcCWACWQAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  )
}

export default ClipboardPin
