import React from 'react'

import { AppFC } from 'types'

const AppDashboardGridItemTitle: AppFC = ({ children }) => {
  return (
    <div className="border-b border-grey-darker w-full py-2 ">
      <h3 className="text-2xl font-bold">{children}</h3>
    </div>
  )
}

export default AppDashboardGridItemTitle
