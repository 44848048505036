import moment from 'moment'
import React from 'react'

interface IAppDashboardHeaderProps {
  activatedAt: string
  userName: string
}

export const AppDashboardHeader: React.FC<IAppDashboardHeaderProps> = ({
  activatedAt,
  userName,
}) => {
  const isActivatedToday = moment().isSame(moment(activatedAt), 'day')
  return (
    <div className="flex flex-col items-center justify-center mt-8 lg:mt-0">
      <h1 className="font-bold text-2xl lg:text-[38px]">
        {`${isActivatedToday ? 'Hi' : 'Welcome Back'} ${userName}`}
      </h1>
      <span className="font-light text-[15px]">
        There is nothing that you can't accomplish! Let's get started
      </span>
    </div>
  )
}
