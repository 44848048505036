import styled from 'styled-components'
import tw from 'twin.macro'

export interface IAppDropZoneProps {
  hasPadding: boolean
}

export const AppAcceptedFileType = styled.div.attrs({
  className: 'block text-xs text-center leading-4 !pointer-events-none',
})<IAppDropZoneProps>`
  ${(p) => (p.hasPadding ? tw`pt-4` : '')}
`
export const AppFileType = styled.p`
  ${tw`text-xs text-center leading-4 px-6 pt-2 pb-7 xs:block sm:hidden`}
`

export const AppDropFileWrapper = styled.div`
  ${tw`flex flex-col justify-center items-center xs:pt-0 sm:px-4 sm:pt-6 sm:pb-11 relative bg-contain bg-no-repeat`} {
  }
`

export const AppUploadFileWrapper = styled.div`
  input[type='file'] {
    display: none;
  }
  .questionLabel {
    padding: 10px;
    background: white;
    color: #5e06b9;
  }
`
