import React, { useEffect, useState } from 'react'
import { AppDashboardGridItemWrapper } from '../style'
import ClipboardPin from 'components/Common/SvgIcons/ClipboardPin'
import StairsUpIcon from 'components/Common/SvgIcons/StairsUpIcon'
import StairsDownIcon from 'components/Common/SvgIcons/StairsDownIcon'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { useGetBusinessMetrics } from 'appHooks/useGetBusinessMetrics'
import { AppTrendNeutralIcon } from 'components/Common/AppSvgIcons/AppTrendNeutralIcon'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppToggleButton } from 'components/Common/AppToggleButton/AppToggleButton.component'
import {
  BusinessMetricsViewPermissionState,
  useGetBusinessProfileQuery,
  useUpdateBusinessProfileMutation,
} from '__generated__/api-types-and-hooks'
import { getTenantId } from 'utils/getTenantId'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'

interface IAppDashboardSummaryCardProps {
  displayHeader: string
  displayMetric: string
  metricPercentage: string
  metricSummary?: string
  isLoading?: boolean
  hideMetrics?: boolean
}

interface IAppDashboardTrendCardsProps {
  trend: ITrend
  isLoading?: boolean
}
interface ITrend {
  displayHeader: string
  displayMetric: string
  metricPercentage: string
  trendDir: 'up' | 'down' | 'nil'
}

const leadCountToolTipText = `Total number of potential clients/customers that show interest
  in your business through online or offline actions (eg. signups, walk-ins, ads etc)
  and have the potential to convert into paying customers.`

const leadConversionRateToolTipText = `The percentage of potential clients/customers that showed
  interest in your business through online  or offline actions (eg. signups, walk-ins, ads
  etc) that converted into paying customers.`

const AppDashboardTrendsSummary = () => {
  const tenantId = getTenantId()
  const role = getRoleFromPath()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const userId = useSelector((state: RootState) => state.user.user.id)
  const [isMetricsViewEnabled, setIsMetricsViewEnabled] = useState<boolean>(false)

  const {
    isRevenueLoading,
    isNetProfitLoading,
    isNumberOfLeadsLoading,
    isLeadsConversionRateLoading,
    sales,
    netProfit,
    leads,
    leadsConversion,
  } = useGetBusinessMetrics()

  const { data: businessData } = useGetBusinessProfileQuery(
    { id: clientId ?? userId },
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    setIsMetricsViewEnabled(
      businessData?.getBusinessProfile?.businessMetricsViewPermission ===
        BusinessMetricsViewPermissionState.Granted
    )
  }, [businessData])

  const { mutate: updateBusinessProfile } = useUpdateBusinessProfileMutation()
  const handleShareToggle = (flag: boolean) => {
    setIsMetricsViewEnabled(flag)
    updateBusinessProfile({
      input: {
        id: clientId ?? userId,
        tenantId,
        businessMetricsViewPermission: flag
          ? BusinessMetricsViewPermissionState.Granted
          : BusinessMetricsViewPermissionState.NotGranted,
      },
    })
  }

  return (
    <AppDashboardGridItemWrapper>
      <div className="flex items-center justify-between border-b border-grey-darker w-full py-2">
        <h3 className="text-2xl font-bold">This month</h3>
        {/* Show for bso client view */}
        {!role?.includes(ROLES.BUSINESS_OWNER) ? (
          <div className="flex items-center space-x-8">
            <AppButton variant="primary" size="sm" label="Request access to veiw" disabled={true} />
            <div className="flex items-center space-x-2">
              <h3 className="font-normal text-sm">View Only</h3>
              <AppToolTip variant="primary" name={'views'} text={'You can only view'}>
                <AppToolTipIcon />
              </AppToolTip>
            </div>
          </div>
        ) : (
          // else show for bo view
          <div className="flex items-center space-x-8">
            <span className="font-normal text-sm">Share with consultant</span>
            <AppToggleButton
              isToggled={isMetricsViewEnabled}
              toggleButtonHandler={() => handleShareToggle(!isMetricsViewEnabled)}
              size="small"
              showDefaultLabel={false}
            />
          </div>
        )}
      </div>
      <div className="mt-4">
        <div className="space-y-4 lg:space-y-0 lg:flex lg:items-center lg:justify-between">
          <AppDashboardSummaryCard
            displayHeader={sales.displayHeader}
            displayMetric={sales.displayMetric}
            metricPercentage={sales.metricPercentage}
            metricSummary={sales.metricSummary}
            isLoading={isRevenueLoading}
            hideMetrics={!isMetricsViewEnabled && clientId}
          />
          <AppDashboardSummaryCard
            displayHeader={netProfit.displayHeader}
            displayMetric={netProfit.displayMetric}
            metricPercentage={netProfit.metricPercentage}
            metricSummary={netProfit.metricSummary}
            isLoading={isNetProfitLoading}
            hideMetrics={!isMetricsViewEnabled && clientId}
          />
        </div>
        <div className="mt-6">
          <hr className="border-b-[0.5px] border-grey-darker" />
          <div className="space-y-4 lg:space-y-0 lg:flex lg:items-center lg:justify-between py-8">
            <div className="lg:min-w-[48%] space-y-4">
              <div className="flex items-center space-x-2">
                <h3 className="font-semibold text-base">Number of Leads</h3>
                <AppToolTip variant="primary" name={'leadCount'} text={leadCountToolTipText}>
                  <AppToolTipIcon />
                </AppToolTip>
              </div>
              {!isMetricsViewEnabled && clientId ? (
                <div className="h-[100px] bg-app-white-100 flex items-center justify-center font-medium text-sm rounded-xl border border-app-grey-25">
                  You cannot view this information
                </div>
              ) : (
                <AppDashboardTrendCards
                  trend={leads as ITrend}
                  isLoading={isNumberOfLeadsLoading}
                />
              )}
            </div>
            <div className="lg:min-w-[48%] space-y-4">
              <div className="flex items-center space-x-2">
                <h3 className="font-semibold text-base">Lead Conversion Rate</h3>
                <AppToolTip
                  variant="primary"
                  name={'leadConversionRate'}
                  text={leadConversionRateToolTipText}
                >
                  <AppToolTipIcon />
                </AppToolTip>
              </div>
              {!isMetricsViewEnabled && clientId ? (
                <div className="h-[100px] bg-app-white-100 flex items-center justify-center font-medium text-sm rounded-xl border border-app-grey-25">
                  You cannot view this information
                </div>
              ) : (
                <AppDashboardTrendCards
                  trend={leadsConversion as ITrend}
                  isLoading={isLeadsConversionRateLoading}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </AppDashboardGridItemWrapper>
  )
}

const AppDashboardSummaryCard: React.FC<IAppDashboardSummaryCardProps> = ({
  displayHeader,
  displayMetric,
  metricPercentage,
  metricSummary,
  isLoading,
  hideMetrics,
}) => {
  return (
    <div className="border border-grey-darker rounded-xl py-2 px-4 min-h-[100px] min-w-full lg:min-w-[48%] space-y-2">
      {!isLoading ? (
        <>
          <div className="flex items-center justify-between">
            <span className="font-semibold text-base">{displayHeader}</span>
            <ClipboardPin />
          </div>
          {!hideMetrics ? (
            <div>
              <h4 className="font-semibold text-xl">{displayMetric}</h4>
              <div className="flex items-center space-x-4">
                {metricPercentage && (
                  <span className="font-medium text-base text-green">{metricPercentage}</span>
                )}
                <span className="font-medium text-base">{metricSummary}</span>
              </div>
            </div>
          ) : (
            <div className="h-[70px] bg-app-white-100 flex items-center justify-center font-medium text-sm rounded-xl border border-app-grey-25">
              You cannot view this information
            </div>
          )}
        </>
      ) : (
        <div className="flex justify-center items-center">
          <GradientCircleNotchIcon width="100" height="100" />
        </div>
      )}
    </div>
  )
}

const AppDashboardTrendCards: React.FC<IAppDashboardTrendCardsProps> = ({ trend, isLoading }) => {
  if (trend.trendDir === 'up') {
  }
  return (
    <div className="flex items-center rounded-xl min-h-[100px] min-w-full bg-grey-light shadow-md">
      {!isLoading ? (
        <>
          <div className="flex items-center justify-center border-r border-[#DDE3E6] min-h-[100px] w-[35%]">
            {trend.trendDir === 'up' && <StairsUpIcon />}
            {trend.trendDir === 'down' && <StairsDownIcon />}
            {trend.trendDir === 'nil' && <AppTrendNeutralIcon />}
          </div>
          <div className="w-[65%] min-h-[100px] space-y-2 px-2 py-3">
            <div className="font-semibold text-base">{trend.displayHeader}</div>
            <div>
              <h4 className="font-semibold text-xl">{trend.displayMetric}</h4>
              <h6
                className={`${
                  trend.trendDir === 'nil'
                    ? 'text-app-orange-5'
                    : trend.trendDir === 'up'
                    ? 'text-green'
                    : 'text-primary-error'
                }  font-normal text-sm`}
              >
                {trend.metricPercentage}
              </h6>
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center w-full">
          <GradientCircleNotchIcon width="100" height="100" />
        </div>
      )}
    </div>
  )
}

export default AppDashboardTrendsSummary
