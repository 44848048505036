import React from 'react'

const StairsDownIcon = () => {
  return (
    <svg
      width="63"
      height="56"
      viewBox="0 0 63 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="63" height="56" fill="url(#pattern0_674_2476)" />
      <defs>
        <pattern
          id="pattern0_674_2476"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_674_2476" transform="matrix(0.00888889 0 0 0.01 0.0555556 0)" />
        </pattern>
        <image
          id="image0_674_2476"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkUlEQVR4nO3aS24UMRDG8RIiBxl7DjDuPkA23ADCI7xXcJ1wBcThXJUFCIQQGWg0EylImZcSQvdX5e8v9WJWbfVPbWvcFmF7sy4PY177R8OEIGARBCyCgMU5HiyCgEUQsAgCFkHAIghYBAGLIGARBCyCgEUQsAgCFkHAIghYBAGLIGARBCyCgEUQsAhyw3gIASzjQTasjCBYGUGwMp6NxcoIgpURBCsjCFZGEKyMIFgZQbAygmBlBMHKCIKVEQQrIwhWRhCsjCBYGUGwMoJgZQTBygiClTkFsT6dWkmmJX8+79NriZI5BKklvdUu//o77vRt6PujUW7OQwiHMPKgJX0Zjo/vyxgR5ADG6neZv5CxIshltUtvtmD8tpLey5gRRPZhvBsVYxuINFZFwmgdpKJhtAyiJT3VkpfXF/DJ/3O0CKKoGC2CKDJGayCKjtESiHrAaAVEvWC0AKJrjHThAiM6iJb8ZAOj5CUsRmQQ3YWxyK8EuYgg6hUjIoh6xogGot4xIoFoBIwoINrlxyEwIoDoDgwr+aV4zDOIRsPwDKIRMbyC6JQY1x/Y/74EPJ36zSAIEMYqghzCGPEQ2yqCiGg/P4HA2AYijaVIGJ5Bhr4/Wm9n9POT2x5U3vqlr+SlLtIzmSqvIFryp6uH2KWPd/Klb2oMzyDWpR9X4y75+11sFK6QZOr8guRbjRtuzWgZpC7mj6AxWgKpuzC62XNBqgWQ6gWjBZDqCSM6SPWGERmkesSIClK9YkQEqZcYPzcw+nQqHooEUsvsoWuMSCA1AkYkEN1YM9LFaptEvBUFxLy/GVFBFGEL/V+KBmI3vtJX69KHQeSeIESQvIY5X8weCEIEyQSBm7JKPuOUxWJNWRK0P41jy89hZVnMAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export default StairsDownIcon
