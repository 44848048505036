import styled from 'styled-components'
import tw from 'twin.macro'

export const AppCheckBoxSelectWrapper = styled.div`
  ${tw`border border-app-grey-30 rounded-lg py-2 bg-app-white-10 appShadow-md`}
`

export const AppCheckBoxSelectInput = styled.input`
  ${tw` py-[10px] px-4 border-none focus:outline-none focus:ring-0 hover:border-none w-full leading-app-body-small-line-height bg-transparent`}
`

export const AppCheckBoxSelectOptionContainer = styled.div<{ isSelected: boolean }>`
  ${tw`flex items-center py-[10px] px-4 cursor-pointer rounded-lg`}
  ${({ isSelected }) => isSelected && tw`text-app-black-100`}
`
