import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { AppErrorMessage } from 'components/Common/AppErrorMessage/AppErrorMessage.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'

interface IAppTaskNameFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
}

export const AppTaskNameField: React.FC<IAppTaskNameFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
}) => {
  const nameWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (nameWrapperRef.current && !nameWrapperRef.current.contains(event.target as Node)) {
        handleClick(TaskFormFields.name, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  return (
    <>
      <Controller
        name={TaskFormFields.name}
        control={control}
        render={({ field }) => (
          <>
            {!clickedFields.name ? (
              <div
                className="col-span-7 font-inter text-2xl text-app-grey-50 truncate w-5/6"
                onClick={() => handleClick(field.name, true)}
              >
                {field.value ? field.value : 'Type play name..'}
                {errors.name && (
                  <AppErrorMessage id={field.name} size="sm">
                    {errors.name.message}
                  </AppErrorMessage>
                )}
              </div>
            ) : (
              <div className="flex items-center w-5/6" ref={nameWrapperRef}>
                <AppTextField
                  type="text"
                  placeholder="Type play name.."
                  size="md"
                  error={errors.name?.message as string}
                  {...field}
                />
              </div>
            )}
          </>
        )}
      />
    </>
  )
}
