import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import moment from 'moment-timezone'

import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { AppErrorMessage } from 'components/Common/AppErrorMessage/AppErrorMessage.component'
import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'

interface AppTaskDateFieldsProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClickStartDate: (field: string, isHovered: boolean) => void
  handleClickExpectedEndDate: (field: string, isHovered: boolean) => void
  isStartDateBeforePlanStartDate?: boolean
}

export const AppTaskDateFields: React.FC<AppTaskDateFieldsProps> = ({
  methods,
  clickedFields,
  handleClickStartDate,
  handleClickExpectedEndDate,
  isStartDateBeforePlanStartDate,
}) => {
  const startDateWrapperRef = useRef<HTMLDivElement | null>(null)
  const expectedEndDateWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        startDateWrapperRef.current &&
        !startDateWrapperRef.current.contains(event.target as Node)
      ) {
        handleClickStartDate(TaskFormFields.startDate, false)
      }
      if (
        expectedEndDateWrapperRef.current &&
        !expectedEndDateWrapperRef.current.contains(event.target as Node)
      ) {
        handleClickExpectedEndDate(TaskFormFields.expectedEndDate, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClickStartDate, handleClickExpectedEndDate])

  return (
    <>
      <FieldLabel>Start date </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.startDate}
          control={control}
          render={({ field }) => (
            <>
              {!clickedFields.startDate ? (
                <div
                  className="col-span-7 font-inter text-black-dark"
                  onClick={() => handleClickStartDate(field.name, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={
                      field.value ? moment(field.value).format('MMM DD, YYYY') : 'Select start date'
                    }
                  />

                  {errors.startDate && !isStartDateBeforePlanStartDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      {errors.startDate.message}
                    </AppErrorMessage>
                  )}
                  {!errors.startDate && isStartDateBeforePlanStartDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      Start date cannot be before plan start date
                    </AppErrorMessage>
                  )}
                </div>
              ) : (
                <div className="flex items-center w-2/3" ref={startDateWrapperRef}>
                  <AppTextField
                    type="date"
                    placeholder="Enter start date"
                    size="sm"
                    error={errors.startData?.message as string}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                    onBlur={() => handleClickStartDate(field.name, false)}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>

      <FieldLabel>Due date </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.expectedEndDate}
          control={control}
          render={({ field }) => (
            <>
              {!clickedFields.expectedEndDate ? (
                <div
                  className="col-span-7 font-inter text-black-dark"
                  onClick={() => handleClickExpectedEndDate(field.name, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={
                      field.value ? moment(field.value).format('MMM DD, YYYY') : 'Select due date'
                    }
                  />
                  {errors.expectedEndDate && (
                    <AppErrorMessage id={field.name} size="sm">
                      {errors.expectedEndDate.message}
                    </AppErrorMessage>
                  )}
                </div>
              ) : (
                <div className="flex items-center w-2/3" ref={expectedEndDateWrapperRef}>
                  <AppTextField
                    type="date"
                    size="sm"
                    placeholder="Enter due date"
                    error={errors.expectedEndDate?.message as string}
                    {...field}
                    onChange={(e) => {
                      field.onChange(e)
                    }}
                    onBlur={() => handleClickExpectedEndDate(field.name, false)}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}
