import React from 'react'

import { categoryType, getIconByCategory } from 'appConfig/data'

const AppSidebar = ({ selectedFilters, setSelectedFilters }) => {
  const handleNavItemClick = (label) => {
    if (label === 'All Business Functions') {
      setSelectedFilters({ ...selectedFilters, categories: [] })
    } else {
      const filterKey = Object.keys(categoryType).find((key) => categoryType[key] === label)
      if (filterKey) {
        setSelectedFilters({ ...selectedFilters, categories: [filterKey] })
      }
    }
  }

  return (
    <div className="hidden smd:block sm:w-64 rounded-lg bg-background-appLight p-4 py-7">
      {/* <aside className="xs:w-full xs:hidden smd:block sm:h-full sm:w-64 rounded-lg bg-background-appLight p-4 py-7 border"> */}
      <nav className="flex flex-wrap sm:flex-col sm:gap-y-1 items-center">
        <AppNavItem
          label="All Business Functions"
          onClick={handleNavItemClick}
          selectedFilters={selectedFilters}
        />
        {Object.values(categoryType).map((categoryLabel) => (
          <AppNavItem
            key={categoryLabel}
            label={categoryLabel}
            iconSrc={getIconByCategory(categoryLabel)}
            onClick={handleNavItemClick}
            selectedFilters={selectedFilters}
          />
        ))}
      </nav>
    </div>
  )
}

interface NavItemProps {
  label: string
  iconSrc?: string
  onClick: (label: string) => void
  selectedFilters: { categories: string[] }
}

const AppNavItem: React.FC<NavItemProps> = ({ label, iconSrc, onClick, selectedFilters }) => {
  const filterKey = Object.keys(categoryType).find((key) => categoryType[key] === label)
  const isSelected =
    label === 'All Business Functions'
      ? selectedFilters.categories?.length === 0
      : filterKey
      ? selectedFilters.categories?.includes(filterKey)
      : false

  return (
    <span
      onClick={() => onClick(label)}
      className={`sm:w-full font-inter font-normal leading-5 text-sm p-2
       relative ${
         isSelected
           ? 'bg-background-hover text-black-appDark !font-medium'
           : 'hover:bg-background-hover text-black-appDime'
       } rounded-lg `}
    >
      {label}
    </span>
  )
}

export default AppSidebar
