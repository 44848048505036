import React, { FC } from 'react'
import { Tooltip, PlacesType } from 'react-tooltip'
import { AppToolTipWrapper } from './AppToolTip.style'

export interface IAppToolTip {
  name: string
  text?: string
  renderContent?: () => React.ReactNode
  place?: PlacesType
  children: React.ReactChild | React.ReactNode
  variant: 'primary' | 'clear'
}

export const AppToolTip: FC<IAppToolTip> = ({
  name,
  text,
  renderContent,
  children,
  variant,
  place,
}) => {
  return (
    <span
      data-tooltip-id={name}
      data-testid={name}
      {...(text && { 'data-tooltip-content': text })}
      className="text-primary-disabledTextLight text-center"
    >
      <AppToolTipWrapper variant={variant}>
        <Tooltip
          id={name}
          style={{
            zIndex: 1000,
            backgroundColor: variant === 'primary' ? '#705AF8' : '#FFF',
            color: variant === 'primary' ? '#ECECEC' : '#121212',
            maxWidth: 320,
            textAlign: 'left',
          }}
          opacity={100}
          noArrow
          place={place}
          {...(renderContent && { render: renderContent })}
        />
      </AppToolTipWrapper>
      {children}
    </span>
  )
}
