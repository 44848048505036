import React from 'react'

import { AppFC } from 'types'
import useDateLabeler from 'hooks/useDateLabeler'
import { AppActionCard } from 'components/Common/AppActionCard/AppActionCard.component'
import history from 'utils/history'
import { useTenantId } from 'utils/useTenantId'
import { getRoleFromPath } from 'utils/helper'

export interface IEventCardProps {
  startDate: string
  endDate: string
  description: string
  eventId: string
}

export const AppEventCard: AppFC<IEventCardProps> = ({
  startDate,
  endDate,
  description,
  eventId,
}) => {
  const startDateLabeler = useDateLabeler(startDate)
  const endDateLabeler = useDateLabeler(endDate)
  const tenantId = useTenantId()
  return (
    <AppActionCard
      className="hover:shadow-sm"
      onClick={() => history.push(`/${getRoleFromPath()}/${tenantId}/calendar?eventId=${eventId}`)}
      leftSlot={() => (
        <div className="flex flex-col justify-center items-center space-y-4 w-[102px] min-w-[102px]">
          <p className="text-purple-appPurple1x leading-5 font-semibold text-lg">
            {startDateLabeler.abbreviatedDayOfWeek}
          </p>
          <p className="text-black leading-4 font-semibold text-xl">{startDateLabeler.day}</p>
        </div>
      )}
    >
      <div className="flex flex-col" data-testid="upcomingEvent">
        <p className="text-sm font-normal whitespace-nowrap">
          {startDateLabeler.timeOfDay} - {endDateLabeler.timeOfDay}
        </p>
        <span className="!text-base !leading-6 font-semibold">{description}</span>
      </div>
    </AppActionCard>
  )
}
