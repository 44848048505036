import { AppFC } from 'types'
import React, { ReactElement } from 'react'
import { AppCard } from 'components/Common/AppCard/AppCard.component'

interface IActionCardProps {
  leftSlot?: () => ReactElement | null
}
/** A simple, horizontal card with a (->) icon that does something when the user clicks it. */
export const AppActionCard: AppFC<IActionCardProps> = ({
  onClick,
  leftSlot = null,
  children,
  className = '',
}) => {
  return (
    <AppCard
      onClick={onClick}
      className={`hover:cursor-pointer border border-grey-light !bg-grey-light !p-0 ${className}`}
    >
      <div className="w-full h-full flex justify-between">
        {leftSlot && (
          <div className="bg-white rounded-tl-[20px] rounded-bl-[20px] flex justify-center items-center">
            {leftSlot()}
          </div>
        )}
        <div className="py-[18px] xs:px-4 sm:px-6 flex justify-between items-center gap-2 w-full">
          {children || <div />}
        </div>
      </div>
    </AppCard>
  )
}
