import React, { useState } from 'react'

import { AppAlertIcon } from 'components/Common/AppSvgIcons/AppAlertIcon'
import {
  AppFieldWrapper,
  AppErrorWrapper,
  AppFieldParentWrapper,
  AppPrefixDividerWrapper,
  AppPrefixWrapper,
  AppErrorMessageWrapper,
  AppLabelWrapper,
  AppInputWrapper,
} from 'components/Common/AppFormFields/AppCommonStyles/AppFormField.style'
import appColors from 'appColors'

interface IconProps {
  color?: string
}

export interface AppTimeFieldProps {
  value?: string
  error?: string
  label?: string
  disabled?: boolean
  placeholder?: string
  prefix?: string
  size: 'lg' | 'md' | 'sm'
  onChange?: (value: string) => void
  Icon?: React.ComponentType<IconProps>
  onBlur?: (value: string) => void
  name?: string
}

export const AppTimeField: React.FC<AppTimeFieldProps> = ({
  size,
  Icon,
  value,
  label,
  disabled,
  onChange,
  error = '',
  placeholder = 'hh:mm',
  prefix,
  name,
}) => {
  const [inputValue, setInputValue] = useState(value)
  const [errorMessage, setErrorMessage] = useState('')

  // Format the input value to hh:mm
  const formatInputValue = (val: string) => {
    const timeRegex = /^(\d{0,2}):?(\d{0,3})$/ // Allow up to 3 digits for minutes
    const match = val.match(timeRegex)

    if (match) {
      let hours = match[1] || ''
      let minutes = match[2] || ''

      // Ensure minutes is at most two digits
      if (minutes.length > 2) {
        minutes = minutes.substring(0, 2) // Take only the first two digits
      }

      // Ensure the hours and minutes are valid
      const formattedHours = hours.padStart(2, '0')
      const formattedMinutes = minutes.padStart(2, '0')

      return `${formattedHours}:${formattedMinutes}`
    }

    return val
  }

  // Handle input changes and apply mask validation
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value
    // Format the value before setting it
    val = formatInputValue(val)

    // Extract hours and minutes to validate
    let [hours, minutes] = val.split(':')

    // Check if the minutes value is greater than 59
    if (parseInt(minutes, 10) > 59) {
      const min = Math.floor(parseInt(minutes) / 60)
      // Set minutes to 00 and increment the hours
      minutes = `${parseInt(minutes, 10) % 60}`.padStart(2, '0')
      hours = (parseInt(hours, 10) + min).toString().padStart(2, '0') // Increase hour by 1
    }

    // Ensure the time is properly formatted
    const formattedValue = `${hours}:${minutes}`

    setInputValue(formattedValue) // Update input value
    setErrorMessage('') // Clear any error messages

    onChange && onChange(formattedValue) // Call the onChange callback
  }

  return (
    <div className="w-full">
      {label && <AppLabelWrapper>{label}</AppLabelWrapper>}
      <AppFieldParentWrapper>
        <AppFieldWrapper prefix={prefix} size={size} error={!!errorMessage} disabled={!!disabled}>
          {Icon && <Icon />}
          {prefix && (
            <>
              <AppPrefixWrapper size={size}>{prefix}</AppPrefixWrapper>
              <AppPrefixDividerWrapper size={size}>
                <div className="self-stretch bg-app-grey-40 relative w-px" />
              </AppPrefixDividerWrapper>
            </>
          )}
          <AppInputWrapper
            type={'text'}
            value={inputValue}
            disabled={disabled}
            onChange={(e) => {
              let newValue = e.target.value
              // Remove any non-numeric characters (except for the colon)
              newValue = newValue.replace(/[^0-9:]/g, '')
              // If the colon exists and it's in the wrong place, fix it
              if (newValue.length === 2 && !newValue.includes(':')) {
                newValue = newValue + ':' // Add colon between hours and minutes
              }
              // Set the value with formatted input
              setInputValue(newValue)
            }}
            onBlur={handleChange}
            error={errorMessage || error}
            prefix={prefix}
            placeholder={placeholder}
            data-testid={name}
          />
        </AppFieldWrapper>
        {errorMessage && <AppErrorMessage error={errorMessage} />}
      </AppFieldParentWrapper>
    </div>
  )
}

export const AppErrorMessage = ({ error }) => {
  return (
    <AppErrorWrapper>
      <AppAlertIcon color={appColors['app-red']['70']} />
      <AppErrorMessageWrapper>{error}</AppErrorMessageWrapper>
    </AppErrorWrapper>
  )
}
