import React, { useEffect, useRef, useMemo } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { FieldLabel, UserName } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { getInitials } from 'components/Chat/helper'
import { BusinessUserType, useGetBusinessProfileQuery } from '__generated__/api-types-and-hooks'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
interface IAppTaskAssigneeFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
  userAvatar?: string
}

export const AppTaskAssigneeField: React.FC<IAppTaskAssigneeFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
  userAvatar,
}) => {
  const assigneWrapperRef = useRef<HTMLDivElement | null>(null)
  const { clientId } = useParams()
  const currentUser = useSelector((state: RootState) => state.user.user)
  const { data: businessProfileData } = useGetBusinessProfileQuery(
    {
      id: clientId || currentUser.id,
    },
    { refetchOnWindowFocus: false }
  )
  const advisors = businessProfileData?.getBusinessProfile?.advisors

  const assigneeOptions = useMemo(() => {
    if (!businessProfileData?.getBusinessProfile?.users) return []

    const userOptions = businessProfileData.getBusinessProfile.users.map((user) => ({
      value: user.userId,
      label: `${user.user.firstName} ${user.user.lastName}`,
      type: user.type,
      user: user.user,
    }))

    if (advisors?.length) {
      advisors.forEach((advisor) => {
        userOptions.push({
          value: advisor.id,
          label: `${advisor.firstName} ${advisor.lastName}`,
          type: BusinessUserType.Member,
          user: {
            id: advisor.id,
            firstName: advisor.firstName,
            lastName: advisor.lastName,
          },
        })
      })
    }

    return userOptions
  }, [advisors, businessProfileData?.getBusinessProfile?.users])

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (assigneWrapperRef.current && !assigneWrapperRef.current.contains(event.target as Node)) {
        handleClick(TaskFormFields.assigneeId, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])
  return (
    <>
      <FieldLabel>Assignee </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.assigneeId}
          control={control}
          defaultValue={currentUser.id}
          render={({ field }) => {
            return (
              <>
                {clickedFields.assigneeId ? (
                  <div className="flex items-center w-2/3" ref={assigneWrapperRef}>
                    <AppSelectField
                      size="sm"
                      {...field}
                      options={assigneeOptions}
                      placeholder="Select assignee"
                      errors={errors.assigneeId?.message as string}
                      onBlur={() => {
                        handleClick(field.name, false)
                      }}
                      onChange={(e) => {
                        field.onChange(e.value)
                        handleClick(field.name, false)
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="flex justify-left gap-3 items-center"
                    onClick={() => handleClick(field.name, true)}
                  >
                    {field.value ? (
                      <>
                        <AppAvatar
                          size={'sm'}
                          type={'mixed'}
                          shape={'Rounded'}
                          src={userAvatar}
                          text={getInitials({
                            firstName:
                              assigneeOptions.find((user) => user.value === field.value)?.user
                                .firstName ?? '',
                            lastName:
                              assigneeOptions.find((user) => user.value === field.value)?.user
                                .lastName ?? '',
                          })}
                        />
                        <UserName>
                          {assigneeOptions.find((user) => user.value === field.value)?.label}
                        </UserName>
                      </>
                    ) : (
                      <div className="!font-inter text-black-dark">Select assignee</div>
                    )}
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}
