import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { statusLabels, userTaskItemStatusesStatus } from 'appConfig/data'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'

interface IAppTaskStatusFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
}

export const AppTaskStatusField: React.FC<IAppTaskStatusFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
}) => {
  const statusWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (statusWrapperRef.current && !statusWrapperRef.current.contains(event.target as Node)) {
        handleClick(TaskFormFields.status, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  return (
    <>
      <FieldLabel>Status </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.status}
          control={control}
          render={({ field }) => (
            <>
              {clickedFields.status ? (
                <div className="flex items-center w-2/3" ref={statusWrapperRef}>
                  <AppSelectField
                    size="sm"
                    {...field}
                    options={statusLabels}
                    placeholder="Select status"
                    errors={errors.status?.message as string}
                    onBlur={() => handleClick(field.name, false)}
                    onChange={(e) => {
                      field.onChange(e.value)
                      handleClick(field.name, false)
                    }}
                  />
                </div>
              ) : (
                <div
                  className="col-span-7 font-inter text-primary-disabledTextLight py-1"
                  onClick={() => handleClick(field.name, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={userTaskItemStatusesStatus[field.value] ?? 'Select status'}
                  />
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}
