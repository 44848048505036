import React from 'react'

const StairsUpIcon = () => {
  return (
    <svg
      width="54"
      height="54"
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="54" height="54" fill="url(#pattern0_674_2469)" />
      <defs>
        <pattern
          id="pattern0_674_2469"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_674_2469" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_674_2469"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACpklEQVR4nO3cXW4TMRiFYcMOEGtiAYi/sUOBXrEDz+ECZQ/dAYqdVbQbAq56CYUiQ6pWZZoqVTs53+fzSpESJTOy5pFHycSaENTWUNP5nI/to1FBIGQJhCyBkKVzPFkCIUsgZAmELIGQJRCyBEKWQMgSCFkCIUsgZAmELIGQJRCyBEKWQMgSCFkCIUsgZAlkx7QIgSxoIRtXEAhXEAhX0NpYriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQriAQrrAHEHw5eIqSTlDSb90FiAGkxs9mb8vkcRECSlpaHLdfkJqejDUeo8YflsbtFuQujTW+2AYY5kogIXxaLV7eNpv2BhI6CzW9Gkv6efUYXH8tkH3OjJLOsB6iQIgw2vsCmRnjv9NSSWdjSeniMwIhwmgJhAijJRAijJZAZv5quw1js42+9s6JgRoXt2wnEBaMzbYCYcHYbC8QFozNPgRyH+Uah38H/9q1qZLe7LIf1Hh6uY94ei+D6+3iIto1qAmMhrTzvko6ujK7jh5mxI5BMIHRXt8Fo7VcLh/n9fCsPdrzMFceQPJ6eD35O2M9vA3Wsg6SPWFYB8neMCyDZI8YVkHyPjG0CIFsZgjksvbjbu+nKYFsx0BJB2HOBBJ4MKZAQmeBCaN3ELBh9AwCRoxeQXKNAyVGjyD5BoyxxneBoZ5AMjtGTyDZAkYvIHnib1dKjB5A8tR/4DX+osTwDpJvwMBq8T6w5hUkW8TwCoKp1SEWMDyCjCV9+HvwLWJ4AxmtY3gCQY2H5jG8gMALhgcQeMKwDoIJjHbLpFzjx2A1qyBjSWlyZtR4GCxnEuQ8PBpr/O4OwzIISvrmDsP0KWu1eN5QxpK+XtyuwkVWQdwmkEDVH/fB2nsAJxgrAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  )
}

export default StairsUpIcon
