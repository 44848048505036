import React, { FC } from 'react'
import ExclamationIcon from 'components/Common/SvgIcons/ExclamationIcon'
import { FontSize } from 'types'

export interface IAppErrorMessageProps {
  id: string | undefined
  children?: React.ReactNode
  hideExclamationMark?: boolean
  size?: FontSize | ''
}

export const AppErrorMessage: FC<IAppErrorMessageProps> = ({
  id,
  children,
  hideExclamationMark,
  size = 'lg',
}) => {
  const sizeClasses = {
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
  }

  return (
    <div className="flex flex-row items-center gap-1 my-2">
      {!hideExclamationMark && (
        <ExclamationIcon className="w-[16px] h-[16px] min-w-[16px] min-h-[16px] fill-primary-error" />
      )}
      <p
        id={`${id}-error`}
        data-testid={`${id}-error`}
        className={`text-primary-error font-primary ${sizeClasses[size] ?? 'text-lg'} font-medium`}
      >
        {children}
      </p>
    </div>
  )
}
