import React, { useMemo } from 'react'

import { getCalendarEvents } from './AppFutureEvents.helper'
import {
  CalendarEventViewPreference,
  useGetUserCalendarEventsQuery,
} from '__generated__/api-types-and-hooks'
import { useTenantId } from 'utils/useTenantId'
import { IUpcomingEvent } from 'types'
import { USER_ROLES } from 'config'
import AppDashboardUpcomingEvents from '../AppDashboardUpcomingEvents'

interface IFutureEventsPayload {
  calendarEventViewPreference: string
  role: string[]
}

const AppFutureEvents = ({ calendarEventViewPreference, role }: IFutureEventsPayload) => {
  const tenantId = useTenantId()
  const { data, isFetching } = useGetUserCalendarEventsQuery(
    {
      calendarName:
        calendarEventViewPreference === CalendarEventViewPreference.Internal
          ? CalendarEventViewPreference.Internal
          : '',
      tenantId,
    },
    { staleTime: 60000 }
  )
  const { futureMeetings } = getCalendarEvents(data?.getUserCalendarEvents?.data)
  /** These are events parsed into the interface required by the UpcomingEvents component */
  const eventSummaries: IUpcomingEvent[] = useMemo(() => {
    const today = new Date().toISOString().slice(0, 10) // e.g., "2024-11-12"

    return futureMeetings
      ? futureMeetings
          .filter((event) => event.startDateTime.slice(0, 10) === today)
          .map((event) => {
            const upcomingEvent: IUpcomingEvent = {
              description: event.title,
              date: event.startDateTime,
              endDate: event.end,
              eventId: event.eventId,
            }
            return upcomingEvent
          })
      : []
  }, [futureMeetings])

  const noEventMessage =
    role?.length && role.includes(USER_ROLES.BUSINESS_OWNER)
      ? 'Get started by scheduling a session with your advisor'
      : 'Get started on your Calendar'

  return (
    <AppDashboardUpcomingEvents
      events={eventSummaries}
      noEventsMessage={noEventMessage}
      loading={isFetching}
    />
  )
}

export default AppFutureEvents
