import React from 'react'

import { AppQuoteIcon } from 'components/Common/AppSvgIcons/AppQuoteIcon'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'

interface AppButtonProps {
  quote: string
  author: string
  onClose: () => void
}

const AppQuote: React.FC<AppButtonProps> = ({ onClose, quote, author }) => {
  return (
    <AppModalBox classes="p-6" height="h-auto" width="w-[320px]">
      <div className=" flex flex-col gap-8">
        <AppQuoteIcon />
        <h2 className="text-center font-semibold text-base">Quote of The Day</h2>
        <p className="text-center text-sm">{quote}</p>
        <p className="text-center font-semibold text-base">{author}</p>

        <div className=" w-full flex justify-center items-center gap-2 p-2 px-4 align-self-stretch">
          <AppButton
            size={'lg'}
            variant={'secondary'}
            label="Close"
            onClick={onClose}
            isFullWidth={true}
          />
        </div>
      </div>
    </AppModalBox>
  )
}

export default AppQuote
