import React, { FC, useState } from 'react'

import { isEmpty } from 'lodash'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { ANNOUNCEMENT_NAMES, ANNOUNCEMENT_FIELD, FORM } from 'config'

import { ModalBoxHeaderWrapper, TextWrapper } from 'components/NotificationPanel/style'
import hookForms from 'utils/hookForms'
import { AppMenu } from 'components/Common/AppMenu/AppMenu.component'
import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppSendMessageIcon } from 'components/Common/AppSvgIcons/AppSendMessageIcon'
import { AppTextArea } from 'components/Common/AppFormFields/AppTextArea/AppTextArea.component'
import { AppFileUploadField } from 'components/Common/AppFileUploadField/AppFileUploadField.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import { AppPaperClipIcon } from 'components/Common/AppSvgIcons/AppPaperClipIcon'

interface BoOption {
  label: string
  value: string
  initials?: string
}
interface IPublishAnnouncement {
  options: BoOption[]
  control: Control<FieldValues>
  isFetchingResponse: boolean
  onSubmit: () => void
  isLoading: boolean
}

const PublishAnnouncement: FC<IPublishAnnouncement> = ({
  control,
  onSubmit,
  options,
  isFetchingResponse,
  isLoading,
}) => {
  const [showUploadModal, setShowUploadModal] = useState(false)
  const formValues = hookForms.getForm(FORM.ANNOUNCEMENT_FORM)?.getValues()

  return (
    <>
      <ModalBoxHeaderWrapper>
        <TextWrapper>To:</TextWrapper>
        <div className="pr-6 w-full mb-6">
          <Controller
            name={ANNOUNCEMENT_FIELD.RECEIVER_IDS}
            control={control}
            defaultValue={['allMyOptions']}
            render={({ field }) => (
              <AppMenu
                options={options}
                placeholder={'All My Clients'}
                {...field}
                isSelectedAll={true}
              />
            )}
          />
        </div>
      </ModalBoxHeaderWrapper>
      <div className="border border-app-grey-20 " />
      <div className="px-6 py-6">
        <Controller
          name={ANNOUNCEMENT_FIELD.TEXT}
          control={control}
          render={({ field }) => (
            <AppTextArea
              rows={8}
              {...field}
              disabled={isFetchingResponse}
              placeholder={ANNOUNCEMENT_NAMES.PLACEHOLDER}
            />
          )}
        />
      </div>
      <div className="border border-app-grey-20 " />
      <div className="flex items-center my-4 mx-4 space-x-2">
        {/* Upload Button */}
        <AppButton
          variant="clear"
          size="sm"
          RightIcon={AppPaperClipIcon}
          onClick={() => setShowUploadModal(!showUploadModal)}
        />
        {/* File name display */}
        {formValues?.fileData && formValues.fileData?.length > 0 && (
          <span className="text-sm text-gray-700">
            {formValues.fileData.map((file, index) => (
              <span key={index} className="truncate">
                {file.name}
              </span>
            ))}
          </span>
        )}
      </div>
      <div className=" flex flex-col gap-2 px-6 py-7 mt-3">
        <Controller
          name={ANNOUNCEMENT_FIELD.SEND_TO_EMAIL}
          control={control}
          defaultValue={true}
          render={({ field }) => (
            <AppCheckBox
              id={ANNOUNCEMENT_FIELD.SEND_TO_EMAIL}
              label={'Notify via email'}
              {...field}
            />
          )}
        />

        <Controller
          name={ANNOUNCEMENT_FIELD.SEND_TO_SMS}
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <AppCheckBox
              id={ANNOUNCEMENT_FIELD.SEND_TO_SMS}
              label={'Notify via text'}
              {...field}
              toolTipText={'For clients that have opted in to SMS messaging'}
            />
          )}
        />
      </div>
      <div className="border border-app-grey-20 " />
      <div className="flex justify-end px-8 py-7 bg-app-grey-10">
        <AppButton
          label={'Send'}
          disabled={(isEmpty(formValues[ANNOUNCEMENT_FIELD.TEXT]) ? true : false) || isLoading}
          onClick={() => onSubmit()}
          size={'lg'}
          variant={'primary'}
          RightIcon={AppSendMessageIcon}
        />
      </div>
      {showUploadModal && (
        <AppModalBox title={'Upload File'} onClose={() => setShowUploadModal(false)}>
          <div className="m-10">
            <Controller
              name="fileData"
              control={control}
              render={({ field }) => (
                <AppFileUploadField
                  className="flex-1 "
                  labelFontSize="base"
                  {...field}
                  existingFiles={field.value}
                />
              )}
            />
          </div>
        </AppModalBox>
      )}
    </>
  )
}

export default PublishAnnouncement
