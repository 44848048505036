import React from 'react'
import { AppStyledDotWrapper } from 'components/Common/AppStatusDot/AppStatusDot.style'

export interface AppStatusDotProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  color: 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange'
  outline?: boolean
}

export const AppStatusDot: React.FC<AppStatusDotProps> = ({ size, color, outline }) => {
  return <AppStyledDotWrapper size={size} color={color} outline={outline} />
}
