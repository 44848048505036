import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppErrorMessage } from 'components/Common/AppErrorMessage/AppErrorMessage.component'
import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { AppTimeField } from 'components/Common/AppTimeField/AppTimeField.component'
import { formatTime, formatTimeFieldValue } from 'appUtils'

interface AppHourEstimationFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
}

export const AppHourEstimationField: React.FC<AppHourEstimationFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
}) => {
  const estimatedEffortInMinutesWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        estimatedEffortInMinutesWrapperRef.current &&
        !estimatedEffortInMinutesWrapperRef.current.contains(event.target as Node)
      ) {
        handleClick(TaskFormFields.estimatedEffortInMinutes, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  return (
    <>
      <FieldLabel>
        <AppToolTip
          variant="primary"
          name="estimatedEffortInMinutes"
          text="This is the estimated dedicated time needed to complete the task."
        >
          Estimated Effort
        </AppToolTip>
      </FieldLabel>
      <div className="col-span-7">
        <Controller
          name={TaskFormFields.estimatedEffortInMinutes}
          control={control}
          render={({ field }) => (
            <>
              {!clickedFields.estimatedEffortInMinutes ? (
                <div
                  className="font-inter text-black-dark"
                  onClick={() => handleClick(TaskFormFields.estimatedEffortInMinutes, true)}
                >
                  <AppBadge
                    size="sm"
                    color={'White'}
                    type={'Outline'}
                    label={formatTime(field.value || '')}
                  />
                </div>
              ) : (
                <div className="flex items-center space-x-3  w-3/6">
                  <AppTimeField
                    size={'sm'}
                    {...field}
                    value={formatTimeFieldValue(field.value)}
                    onChange={(e) => {
                      const [hours, minutes] = e.split(':').map(Number)
                      field.onChange(hours * 60 + minutes)
                      handleClick('estimatedEffortInMinutes', false)
                    }}
                  />
                </div>
              )}
              {errors.estimatedEffortInMinutes && (
                <AppErrorMessage id="estimatedEffortInMinutes" size="sm">
                  {errors.estimatedEffortInMinutes.message}
                </AppErrorMessage>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}
