import React, { FC, useState } from 'react'
import { includes } from 'lodash'

import { AppCheckBox } from 'components/Common/AppFormFields/AppCheckBox/AppCheckBox.component'
import {
  AppCheckBoxSelectWrapper,
  AppCheckBoxSelectInput,
  AppCheckBoxSelectOptionContainer,
} from 'components/Common/AppFormFields/AppCheckBoxSelect/AppCheckBoxSelect.style'
import { AppErrorMessage } from '../AppTextField/AppTextField.component'

export interface IAppCheckBoxSelectProps {
  value: string[] | string
  options: {
    value?: string
    label?: string
    noneOfTheAbove?: boolean
  }[]
  placeholder: string
  onChange: (input: string | string[]) => void
  isMulti?: boolean
  isDisabled?: boolean
  id?: string
  error?: string
  disableSearch?: boolean
}

export const AppCheckBoxSelect: FC<IAppCheckBoxSelectProps> = ({
  value,
  options,
  onChange,
  placeholder,
  isMulti = false,
  isDisabled = false,
  error,
  disableSearch = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  const handleSelect = (optionValue: string) => {
    if (isDisabled) return

    const currentValue = Array.isArray(value) ? value : [value]
    const selectedOption = options.find((option) => option.value === optionValue)

    if (selectedOption?.noneOfTheAbove) {
      // Clear all selected values when `noneOfTheAbove` is selected
      return onChange(isMulti ? [optionValue] : optionValue)
    }

    if (isMulti) {
      return handleMultiSelect(currentValue, optionValue)
    }

    // Single selection: set the new value directly
    return onChange(currentValue.includes(optionValue) ? '' : optionValue)
  }

  // Function to handle multi-selection
  const handleMultiSelect = (currentValue: string[], optionValue: string) => {
    const newValue = currentValue.includes(optionValue)
      ? currentValue.filter((item) => item !== optionValue)
      : [...currentValue, optionValue]

    // Remove conflicting `noneOfTheAbove` options
    const conflictingOptions = options.filter(
      (option) => option.noneOfTheAbove && currentValue.includes(option.value ?? '')
    )

    if (conflictingOptions.length) {
      const conflictingValues = conflictingOptions.map((option) => option.value)
      return onChange(newValue.filter((item) => !conflictingValues.includes(item)))
    }

    return onChange(newValue)
  }

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  return (
    <AppCheckBoxSelectWrapper>
      {placeholder && (
        <AppCheckBoxSelectInput
          type="text"
          disabled={disableSearch}
          placeholder={placeholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}

      {filteredOptions.map((option, index) => {
        const isSelected = includes(Array.isArray(value) ? value : [value], option.value)

        return (
          <AppCheckBoxSelectOptionContainer
            key={index}
            isSelected={isSelected}
            onClick={() => handleSelect(option.value || '')}
          >
            <AppCheckBox
              value={isSelected}
              onChange={() => handleSelect(option.value || '')}
              label={option.label}
              variant="tick"
              disabled={isDisabled}
            />
          </AppCheckBoxSelectOptionContainer>
        )
      })}
      <AppCheckBoxSelectOptionContainer isSelected={false}>
        {error && <AppErrorMessage error={error} />}
      </AppCheckBoxSelectOptionContainer>
    </AppCheckBoxSelectWrapper>
  )
}
