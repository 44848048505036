import React from 'react'

export const AppBackButtonArrowIcon = () => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.287477 5.66572C-0.0958256 6.04485 -0.0958256 6.66057 0.287477 7.03971L5.19375 11.8926C5.57705 12.2718 6.19953 12.2718 6.58284 11.8926C6.96614 11.5135 6.96614 10.8978 6.58284 10.5187L3.34776 7.32178L12.7555 7.32178C13.2983 7.32178 13.7368 6.88805 13.7368 6.35119C13.7368 5.81434 13.2983 5.38061 12.7555 5.38061L3.35083 5.38061L6.57977 2.18373C6.96307 1.8046 6.96307 1.18888 6.57977 0.809743C6.19647 0.430607 5.57398 0.430607 5.19068 0.809743L0.28441 5.66268L0.287477 5.66572Z"
        fill="#5E06B9"
      />
    </svg>
  )
}
