import React, { useState } from 'react'
import { AppDashboardGridItemWrapper } from '../style'
import { AppTab, AppTabContainer } from 'components/Common/AppButtonTab/ AppButtonTab.component'
import { AppModalBox } from 'components/Common/AppModalBox/AppModalBox.component'
import AppCrossIcon from 'components/Common/AppSvgIcons/AppModalCrossIcon'
import { AppTask } from 'components/AppKanbanBoard/AppTask/AppTask.component'
import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'
import AppTaskItemIcon from 'components/Common/AppSvgIcons/AppTaskItemIcon'
import { AppPillTextBox } from 'components/Common/AppPillTextBox/AppPillTextBox.component'
import { getColorThemeForGoalPriority } from 'appUtils'
import { BusinessGoalDateFilter, UserTask, UserTaskSource } from '__generated__/api-types-and-hooks'
import { useKanbanBoardData } from 'components/AppKanbanBoard/AppHook/AppKanbanBoard.hook'
import { useGetUserTasks } from 'appHooks/useGetUserTasks'
import { ITaskSummaryItem } from 'components/AppDashboard/interfaces'
import { AppTaskCreationConfirmation } from 'components/AppKanbanBoard/AppTaskCreationConfirmation/AppTaskCreationConfirmation.component'
import { getRoleFromPath } from 'utils/helper'
import { ROLES } from 'config'

interface ITasksSummaryHeaderProps {
  setShowAddTaskModal: (show: boolean) => void
}

interface ITasksSummaryBodyItemsProps {
  tasks: ITaskSummaryItem[]
}

interface ITasksSummaryBodyProps {
  filter: BusinessGoalDateFilter
}

const AppDashboardTasksSummary = () => {
  const [showAddTaskModal, setShowAddTaskModal] = useState(false)
  const [showAddTaskSuccessModal, setShowAddTaskSuccessModal] = useState(false)
  const [showAddTaskErrorModal, setShowAddTaskErrorModal] = useState(false)
  const [createdUserTask, setCreatedUserTask] = useState<UserTask>({})
  const [filter] = useState<Record<string, (string | number)[] | (string | number)>>({
    dateRange: BusinessGoalDateFilter.EntireGamePlan,
  })
  const {
    userName,
    advisorsData,
    logo,
    userId,
    tenantId,
    taskPlan,
    refetch,
    userAvatar,
    userFirstName,
    userLastName,
    goalEndDate,
  } = useKanbanBoardData({ filter })
  return (
    <>
      <AppDashboardGridItemWrapper>
        <div>
          <AppTabContainer
            showActiveTabHeader={true}
            tabContentClassName="mt-4"
            tabHeaderClassName="border-b border-grey-darker w-full py-2"
            tabHeaderTabsClassName="space-x-2 text-xs bg-grey-grey1x shadow-lg rounded border-grey-light"
          >
            <AppTab tabName="Today">
              <TasksSummaryHeader setShowAddTaskModal={setShowAddTaskModal} />
              <TasksSummaryBody filter={BusinessGoalDateFilter.Today} />
            </AppTab>
            {/* <AppTab tabName="Tomorrow">
              <TasksSummaryHeader setShowAddTaskModal={setShowAddTaskModal} />
              <TasksSummaryBody filter={BusinessGoalDateFilter.Tomorrow} />
            </AppTab>
            <AppTab tabName="This week">
              <TasksSummaryHeader setShowAddTaskModal={setShowAddTaskModal} />
              <TasksSummaryBody filter={BusinessGoalDateFilter.ThisWeek} />
            </AppTab> */}
          </AppTabContainer>
        </div>
      </AppDashboardGridItemWrapper>
      {showAddTaskModal && (
        <AppModalBox
          Icon={AppCrossIcon}
          height="max-h-[835px] h-full"
          width="max-w-2xl"
          title={`Create new Play`}
          showBorder={false}
          onClose={() => {
            setShowAddTaskModal(false)
          }}
          onBack={() => {
            setShowAddTaskModal(false)
          }}
        >
          <AppTask
            tenantId={tenantId}
            userId={userId}
            plan={taskPlan}
            source={UserTaskSource.Goal}
            setShowTaskModal={setShowAddTaskModal}
            setShowTaskSuccessModal={setShowAddTaskSuccessModal}
            setShowTaskErrorModal={setShowAddTaskErrorModal}
            setCreatedUserTask={setCreatedUserTask}
            refetch={refetch}
            logo={logo ?? ''}
            goalEndDate={goalEndDate!}
            advisorsData={advisorsData}
            userName={userName}
            userAvatar={userAvatar ?? ''}
            userFirstName={userFirstName ?? ''}
            userLastName={userLastName ?? ''}
          />
        </AppModalBox>
      )}
      {(showAddTaskSuccessModal || showAddTaskErrorModal) && (
        <AppTaskCreationConfirmation
          showAddTaskSuccessModal={showAddTaskSuccessModal}
          setShowAddTaskModal={setShowAddTaskModal}
          setShowAddTaskSuccessModal={setShowAddTaskSuccessModal}
          setShowAddTaskErrorModal={setShowAddTaskErrorModal}
          createdUserTask={createdUserTask}
          userId={userId}
          userAvatar={userAvatar ?? ''}
          userFirstName={userFirstName ?? ''}
          userLastName={userLastName ?? ''}
        />
      )}
    </>
  )
}

const TasksSummaryHeader: React.FC<ITasksSummaryHeaderProps> = ({ setShowAddTaskModal }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-2">
        <h3 className="font-bold text-xl">Plays</h3>
        <AppToolTip variant="primary" name={'tasks'} text={'This is a summary of your tasks'}>
          <AppToolTipIcon />
        </AppToolTip>
      </div>
      <AppButton
        label="Add a Play"
        variant="secondary"
        onClick={() => setShowAddTaskModal(true)}
        size="md"
      />
    </div>
  )
}

const TasksSummaryBody: React.FC<ITasksSummaryBodyProps> = ({ filter }) => {
  const role = getRoleFromPath()
  const { todoTasks, inProgressTasks, blockedTasks } = useGetUserTasks({ filter })
  return (
    <div className="mt-4">
      {role?.includes(ROLES.BUSINESS_OWNER) ? (
        <AppTabContainer tabAlignment="center">
          <AppTab tabName="To Do">
            <TasksSummaryBodyItems tasks={todoTasks} />
          </AppTab>
          <AppTab tabName="In Progress">
            <TasksSummaryBodyItems tasks={inProgressTasks} />
          </AppTab>
          <AppTab tabName="Blocked">
            <TasksSummaryBodyItems tasks={blockedTasks} />
          </AppTab>
        </AppTabContainer>
      ) : (
        <div className="h-[300px] bg-app-white-100 flex items-center justify-center font-medium text-sm">
          You cannot view this information
        </div>
      )}
    </div>
  )
}

const TasksSummaryBodyItems: React.FC<ITasksSummaryBodyItemsProps> = ({ tasks }) => {
  return (
    <div className="mt-8">
      {tasks?.length > 0 ? (
        <div className="space-y-4">
          {tasks?.map((task) => (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2 max-w-[70%]">
                  <AppTaskItemIcon />
                  <span className="font-normal text-[13px]">{task.name}</span>
                </div>
                <div>
                  <AppPillTextBox
                    className="!rounded-md !px-[8px] w-fit"
                    colorTheme={getColorThemeForGoalPriority(task.priorityStatus)}
                    size="small"
                    textSize="small"
                    fontSize="normal"
                  >
                    {task.priorityLabel}
                  </AppPillTextBox>
                </div>
              </div>
            </>
          ))}
        </div>
      ) : (
        <div>No results</div>
      )}
    </div>
  )
}

export default AppDashboardTasksSummary
