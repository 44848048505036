import { RootState } from 'App'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { uploadFileAction } from 'store/actions/common'

const appTaskUserInputFieldsProps = (state: RootState) => {
  return {
    uploadedFileId: state.common.uploadedFile?.id,
    uploadFileLoading: state.common.uploadFileLoading,
    uploadFileErrors: state.common.uploadFileErrors,
  }
}

function appTaskUserInputFieldsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      uploadFileAction: uploadFileAction.STARTED,
      resetUploadFileAction: uploadFileAction.RESET,
    },
    dispatch
  )
}

export const AppTaskUserInputFieldsConnector = connect(
  appTaskUserInputFieldsProps,
  appTaskUserInputFieldsDispatch
) 