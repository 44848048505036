import React, { forwardRef, useEffect, useState } from 'react'

import uploadImageLogo from 'appAssets/upload-image-logo.png'
import InfoText from 'components/Common/InfoText'
import ErrorMessage from 'components/Common/ErrorMessage'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'
import { AppToolTipWithButton } from 'components/Common/AppToolTipWithButton/AppToolTipWithButton.component'

interface IAppFileUploadFieldProps {
  name?: string
  label?: string
  maxFiles?: number
  allowedFormats?: string[] // You can keep this for optional filtering of specific file types
  errors?: any
  disabled?: boolean
  hidden?: boolean
  className?: string
  labelClassName?: string
  labelFontSize?: string
  labelFontWeight?: string
  toolTipText?: string
  toolTipWidth?: string
  toolTipPlace?: string
  toolTipClass?: string
  toolTipOpacity?: number
  toolTipBackgroundColor?: string
  infoText?: string
  onChange?: (files: File[]) => void
  existingFiles?: File[] // To display existing files
  buttonLabel?: string
}

const MemoizeFileUploadField: React.ForwardRefRenderFunction<any, IAppFileUploadFieldProps> = (
  {
    name,
    label,
    maxFiles = 1,
    allowedFormats = [],
    errors,
    disabled,
    hidden,
    className,
    labelClassName,
    labelFontSize,
    labelFontWeight,
    toolTipText,
    toolTipWidth,
    toolTipPlace,
    toolTipClass,
    toolTipOpacity,
    toolTipBackgroundColor,
    infoText,
    onChange,
    existingFiles = [],
    buttonLabel,
  },
  ref
) => {
  const [filePreviews, setFilePreviews] = useState<{ name: string; file: File }[]>([])
  const [isDragging, setIsDragging] = useState(false)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || [])
    processFiles(files)
  }

  const processFiles = (files: File[]) => {
    const validFiles = allowedFormats.length
      ? files.filter((file) => allowedFormats.includes(file.type))
      : files

    if (validFiles.length + filePreviews.length > maxFiles) {
      validFiles.splice(maxFiles - filePreviews.length)
    }

    const newFilePreviews = validFiles.map((file) => ({
      name: file.name,
      file,
    }))
    setFilePreviews((prev) => [...prev, ...newFilePreviews])

    if (onChange) onChange([...filePreviews.map((preview) => preview.file), ...validFiles])
  }

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    const files = Array.from(e.dataTransfer.files || [])
    processFiles(files)
    setIsDragging(false)
  }

  const handleFileDelete = (index: number) => {
    const updatedPreviews = filePreviews.filter((_, i) => i !== index)
    setFilePreviews(updatedPreviews)
    if (onChange) onChange(updatedPreviews.map((preview) => preview.file))
  }

  useEffect(() => {
    if (disabled && filePreviews.length > 0) {
      setFilePreviews([])
    }
  }, [disabled, filePreviews.length])

  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {infoText && <InfoText infoText={infoText} />}
        <AppToolTipWithButton
          label={label}
          toolTipText={toolTipText}
          toolTipWidth={toolTipWidth}
          toolTipClass={toolTipClass}
          toolTipOpacity={toolTipOpacity}
          toolTipBackgroundColor={toolTipBackgroundColor}
        />
        <div
          className={`file-upload-container border-dashed border-2 border-primary-appDarkBorder rounded-lg p-20 text-center cursor-pointer ${
            isDragging ? 'bg-gray-200' : ''
          }`}
          onClick={(e) => {
            e.preventDefault()
            document.getElementById(`${name}`)?.click()
          }}
          onDragOver={(e) => {
            e.preventDefault()
            setIsDragging(true)
          }}
          onDragLeave={() => setIsDragging(false)}
          onDrop={handleDrop}
        >
          <img src={uploadImageLogo} alt="Upload Icon" className="mx-auto mb-2" />
          <p className="text-black-appDark font-inter text-sm font-normal">
            Drop your files here, or{' '}
            <span className="text-purple-strong font-inter text-sm font-normal">
              click to browse
            </span>
          </p>
          <p className="text-sm text-primary-disabledTextLight font-inter text-sm font-normal">
            All file types are supported, up to 5MB.
          </p>
        </div>
        <input
          id={name}
          name={name}
          type="file"
          accept={allowedFormats.length ? allowedFormats.join(',') : undefined}
          multiple
          disabled={disabled}
          onChange={handleFileChange}
          className="hidden"
          ref={ref}
        />
        <div className="file-preview-list mt-4">
          {filePreviews.map((preview, index) => (
            <div key={index} className="relative file-preview-item">
              <p className="text-black-appDark font-inter text-sm">{preview.name}</p>
              <CrossIcon
                className="absolute top-1 right-1 cursor-pointer"
                onClick={() => handleFileDelete(index)}
              />
            </div>
          ))}
          {filePreviews.length === 0 && existingFiles.length > 0 && (
            <div>
              {existingFiles.map((file, index) => (
                <p key={index} className="text-black-appDark font-inter text-sm">
                  {file.name}
                </p>
              ))}
            </div>
          )}
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </div>
  )
}

export const AppFileUploadField = forwardRef(MemoizeFileUploadField)
