import styled from 'styled-components'
import tw from 'twin.macro'

export const TaskDetailsWrapper = styled.div`
  ${tw` p-8 pt-4 bg-white divide-y divide-primary-disabledText flex flex-col gap-8  !font-inter `}
`
export const InformationGrid = styled.div`
  ${tw` grid grid-cols-12 gap-x-8 gap-y-4 text-sm items-center  !font-inter `}
`
export const FieldLabel = styled.div`
  ${tw` col-span-3 text-primary-disabledTextLight  !font-inter py-2`}
`
export const FieldValue = styled.div`
  ${tw` col-span-7  !font-inter `}
`
export const DescriptionWrapper = styled.div`
  ${tw`pt-8 flex flex-col gap-4 !font-inter mb-4 `}
`

export const DescriptionHeader = styled.div`
  ${tw`flex justify-between items-center !font-inter `}
`

export const DescriptionTitle = styled.h4`
  ${tw`text-base text-black font-medium !font-inter `}
`

export const GenerateButton = styled.button`
  ${tw`bg-primary-appBrand flex gap-2 items-center hover:bg-primary-brand text-sm text-white px-3 py-1 rounded-md !font-inter 
 `}
  &:disabled {
    ${tw`bg-[#D6D5FF] cursor-not-allowed `}
  }
`

export const DescriptionText = styled.p`
  ${tw`text-black text-sm font-normal !font-inter `}
`

export const ActivityWrapper = styled.div`
  ${tw`pt-4 flex flex-col gap-4 !font-inter `}
`

export const ActivityContainer = styled.div`
  ${tw`flex gap-3 !font-inter `}
`

export const ActivityContent = styled.div`
  ${tw`bg-background-light flex flex-col gap-1 px-3 py-2 text-sm rounded-lg flex-1 !font-inter `}
`

export const UserInfo = styled.span`
  ${tw`flex gap-1 !font-inter `}
`

export const UserName = styled.h3`
  ${tw`text-black font-normal capitalize !font-inter `}
`

export const DateText = styled.p`
  ${tw`text-[#A5A5A5] !font-inter `}
`

export const TaskInstruction = styled.p`
  ${tw`text-primary-disabledTextLight !font-inter `}
`
