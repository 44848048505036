// TabContainer.tsx
import React, { useState, ReactNode } from 'react'

interface IAppTabContainerProps {
  showActiveTabHeader?: boolean
  tabAlignment?: 'left' | 'center' | 'right'
  tabHeaderClassName?: string
  tabHeaderTabsClassName?: string
  tabContentClassName?: string
  children: ReactNode
}

interface IAppTabProps {
  tabName: string
  children: ReactNode
}

const AppTabContainer: React.FC<IAppTabContainerProps> = ({
  showActiveTabHeader = false,
  tabAlignment = 'right',
  tabHeaderClassName,
  tabHeaderTabsClassName,
  tabContentClassName,
  children,
}) => {
  const tabs = React.Children.toArray(children) as React.ReactElement<IAppTabProps>[]
  const [activeTab, setActiveTab] = useState<string>(tabs[0]?.props.tabName || '')

  const currentTab = tabs.find((tab) => tab.props.tabName === activeTab)

  // Determine the alignment classes based on the `tabAlignment` prop
  const alignmentClasses =
    tabAlignment === 'center'
      ? 'justify-center space-x-4'
      : tabAlignment === 'left'
      ? 'flex-row-reverse justify-between'
      : 'justify-between'

  const headerAlignment = tabAlignment === 'center' ? 'justify-center' : ''

  return (
    <div className="w-full">
      <div
        className={`space-y-4 md:space-y-0 md:flex md:items-center ${alignmentClasses} ${tabHeaderClassName}`}
      >
        <div>
          {showActiveTabHeader && (
            <h2 className="text-lg lg:text-2xl font-bold">{activeTab || 'No active tab'}</h2>
          )}
        </div>
        <div
          className={`flex space-x-4 text-base font-semibold ${headerAlignment} ${tabHeaderTabsClassName}`}
        >
          {tabs.map((tab) => (
            <button
              key={tab.props.tabName}
              onClick={() => setActiveTab(tab.props.tabName)}
              className={`py-1 px-4 rounded  ${
                activeTab === tab.props.tabName ? 'bg-grey-lighter3x shadow-lg' : ''
              }`}
            >
              {tab.props.tabName}
            </button>
          ))}
        </div>
      </div>
      <div className={`${tabContentClassName}`}>{currentTab}</div>
    </div>
  )
}

const AppTab: React.FC<IAppTabProps> = ({ children }) => {
  return <div className="w-full font-inter">{children}</div>
}

export { AppTabContainer, AppTab }
