import { RootState } from 'App'
import {
  BusinessGoal,
  BusinessGoalsFilterData,
  UserTaskItemStatus,
  useGetBusinessGoalsQuery,
} from '__generated__/api-types-and-hooks'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTenantId } from 'utils/getTenantId'

interface IUseGetUserBusinessGoalsInput {
  filter?: BusinessGoalsFilterData
}

export const useGetUserBusinessGoals = (input?: IUseGetUserBusinessGoalsInput) => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const currentUser = useSelector((state: RootState) => state.user.user)
  const [userBusinessGoals, setUserBusinessGoals] = useState<BusinessGoal[]>([])
  const completedTouchdowns = useRef(0)
  const {
    data,
    isLoading: isUserBusinessGoalsLoading,
    isSuccess,
    refetch: refetchUserBusinessGoals,
  } = useGetBusinessGoalsQuery(
    {
      input: { tenantId, userId: clientId || currentUser?.id, filter: input?.filter },
    },
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (data && data.getBusinessGoals) {
      setUserBusinessGoals(data.getBusinessGoals as unknown as BusinessGoal[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const completedUserBusinessGoals = useMemo(
    () => userBusinessGoals.filter((goal) => goal.status === UserTaskItemStatus.Done),
    [userBusinessGoals]
  )

  useEffect(() => {
    let touchdowns = 0
    userBusinessGoals.forEach((goal) => {
      goal.plan?.milestones?.forEach((milestone) => {
        if (milestone.status === UserTaskItemStatus.Done) {
          touchdowns++
        }
      })
    })
    completedTouchdowns.current = touchdowns
  }, [userBusinessGoals])

  return {
    userBusinessGoals,
    isUserBusinessGoalsLoading,
    refetchUserBusinessGoals,
    completedUserBusinessGoals,
    completedTouchdowns: completedTouchdowns.current,
  }
}
