import React, { FC, useEffect, useRef, useState } from 'react'

import {
  AppCardWrapper,
  AppCardDetails,
  AppCardFooter,
  AppDropdownMenu,
  AppDropdownItem,
} from 'components/AppGoalCenter/AppGoalCard/AppGoalCard.style'
import {
  BusinessGoal,
  BusinessGoalPriority,
  OnboardingFlowType,
  useCreateFlowChatSessionMutation,
  UserTaskItemStatus,
  useUpdateBusinessGoalMutation,
} from '__generated__/api-types-and-hooks'
import {
  categoryType,
  goalDropdownItems,
  goalPriorityOptions,
  goalPriorityStatus,
} from 'appConfig/data'
import { getTenantId } from 'utils/getTenantId'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { AppConfirmationModal } from 'components/AppConfirmationModal/AppConfirmationModal.component'
import ThreeDotsIcon from 'components/Common/SvgIcons/ThreeDotsIcon'
import moment from 'moment'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import GradientCircleNotchIcon from 'components/Common/SvgIcons/GradientCircleNotchIcon'
import { AppProgressBar } from 'components/Common/AppProgressBar/AppProgressBar.component'
import { AppBadge, BadgeColor } from 'components/Common/AppBadge/AppBadge.component'
import { useFeatureFlag } from 'hooks/userFeatureFlag'
import { AppGoalSuggestedByBox } from 'components/AppOnboarding/AppChatbot/AppChatBotFlows/AppGoalDetailView/AppGoalSuggestedByBox/AppGoalSuggestedByBox.component'
import history from 'utils/history'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'

export interface ICardProps {
  goalId: string
  title: string
  progress: number | null
  image?: string
  description: string
  category: string
  priority: string
  startDate?: string
  expectedEndDate?: string
  goalStatus?: UserTaskItemStatus | null
  logo: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  refetchGoals?: ({ hasFilter }: { hasFilter?: boolean }) => void
  flagValueUser?: boolean
  allTaskTodo: boolean
  isMyOtherTask: boolean
  openRegeneratePlanPopup: () => void
  isLoading?: boolean
  goal: BusinessGoal
}

export const AppGoalCard: FC<ICardProps> = ({
  goalId,
  title,
  progress,
  isLoading,
  logo,
  priority,
  category,
  startDate,
  expectedEndDate,
  goalStatus,
  onClick,
  refetchGoals,
  flagValueUser,
  description,
  allTaskTodo,
  isMyOtherTask,
  openRegeneratePlanPopup,
  goal,
}) => {
  const tenantId = getTenantId()
  const [goalPriority, setGoalPriority] = useState(goalPriorityStatus[priority])
  const [goalDueDate, setGoalDueDate] = useState(expectedEndDate)
  const [changeCardValue, setChangeCardValue] = useState({ dueDate: false, priority: false })
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [prioritysChipColor, setPriorityChipColor] = useState(priority)
  const [, setShowConfetti] = useState(false)
  const {
    mutate,
    isLoading: isUpdatingGoal,
    isSuccess: isSuccessUpdatingGoal,
    error,
    isError,
  } = useUpdateBusinessGoalMutation()
  const { mutate: createFlowChatSession, isLoading: isLoadingCreateFlow } =
    useCreateFlowChatSessionMutation()
  const userId = useSelector((state: RootState) => state.user.user.id)
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  let statusChipColor = getStatusChipColor(prioritysChipColor)
  const [isOpen, setIsOpen] = useState(false)
  const regeneratePlanFlagEnabled = useFeatureFlag(userId, 'release-regenerate-plan')
  const goalCardMenuEnabled = useFeatureFlag(userId, 'release-goal-card-menu')
  const dropdownRef = useRef<HTMLDivElement>(null)
  const isLoadingGoal = isUpdatingGoal || isLoadingCreateFlow || isLoading
  const handleMarkGoalAsDone = () => {
    mutate({
      input: {
        goalId,
        tenantId,
        status: UserTaskItemStatus.Done,
        progress: 100,
        userId: clientId,
      },
    })
  }

  useEffect(() => {
    if (isSuccessUpdatingGoal && refetchGoals) {
      refetchGoals({ hasFilter: true })
      refetchGoals({})
    }

    if (isSuccessUpdatingGoal && !isConfirmModalOpen) {
      setShowConfetti(true)
      setTimeout(() => setShowConfetti(false), 10000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessUpdatingGoal, isConfirmModalOpen])

  useEffect(() => {
    setGoalDueDate(expectedEndDate)
  }, [expectedEndDate])

  const handleClick = (field: string, value: boolean) => {
    setChangeCardValue((prev) => ({ ...prev, [field]: value }))
  }

  const handleStatusChange = (goalStatus: UserTaskItemStatus, event) => {
    event.stopPropagation()
    mutate({
      input: {
        goalId,
        tenantId,
        status: goalStatus,
        userId: clientId ?? userId,
      },
    })
    setIsOpen(false)
  }
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  return (
    <AppCardWrapper onClick={onClick}>
      <div className="flex items-center gap-2">
        {goal?.suggestedBy && (
          <div className="mt-2">
            <AppGoalSuggestedByBox
              status={goal.status || UserTaskItemStatus.Todo}
              onAccept={() => {
                createFlowChatSession(
                  {
                    input: {
                      flow: OnboardingFlowType.AcceptGoal,
                      metadata: JSON.stringify({
                        goalId,
                      }),
                    },
                  },
                  {
                    onSuccess: (res) => {
                      const { id } = res.createFlowChatSession
                      history.push(`/owner-v2/${tenantId}/onboarding/goal-setting/${id}`)
                    },
                  }
                )
              }}
              onReject={() => {
                mutate({
                  input: {
                    goalId,
                    tenantId,
                    status: UserTaskItemStatus.Archived,
                    userId: clientId ?? userId,
                  },
                })
              }}
              userInitial={`${goal?.suggestedBy?.firstName} ${goal?.suggestedBy?.lastName}`}
            />
          </div>
        )}
      </div>
      {isLoadingGoal ? (
        <div className="flex justify-center items-center pt-8">
          <GradientCircleNotchIcon width="100" height="100" />
        </div>
      ) : (
        <AppCardDetails>
          {!isMyOtherTask && (
            <div className="flex items-center gap-2 justify-between">
              {goalStatus === UserTaskItemStatus.Creating ? (
                <AppBadge size="sm" type="Outline" color={'Disabled'} label="Generating Plan" />
              ) : !changeCardValue.priority ? (
                <AppBadge
                  size="sm"
                  color={statusChipColor}
                  label={goalPriority}
                  type="Filled"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleClick('priority', true)
                  }}
                />
              ) : (
                <div className="w-1/2" onClick={(event) => event.stopPropagation()}>
                  <AppSelectField
                    size="sm"
                    options={goalPriorityOptions}
                    placeholder="Select status"
                    value={priority}
                    onChange={(e) => {
                      setGoalPriority(goalPriorityStatus[e.value])
                      setPriorityChipColor(e.value)
                      mutate({
                        input: {
                          goalId,
                          tenantId,
                          goalPriority: e.value,
                          userId,
                        },
                      })
                      handleClick('priority', false)
                    }}
                    onBlur={() => handleClick('priority', false)}
                  />
                </div>
              )}
              {/* Show/hide three dots menu icon */}
              {(!clientId || goalCardMenuEnabled) && (
                <div
                  id={goalId}
                  onClick={(event) => {
                    event.stopPropagation()
                    if (goalStatus === UserTaskItemStatus.Creating) return
                    setIsOpen(true)
                  }}
                >
                  <ThreeDotsIcon />
                </div>
              )}
              {isOpen && (
                <AppDropdownMenu ref={dropdownRef}>
                  {goalDropdownItems.map(({ status, label, selectedLabel, shouldShow }) =>
                    (label !== 'Regenerate' || regeneratePlanFlagEnabled) && shouldShow(goal) ? (
                      <AppDropdownItem
                        key={status}
                        onClick={(e) => {
                          e.stopPropagation()
                          if (label === 'Regenerate') {
                            openRegeneratePlanPopup()
                            return
                          }
                          if (status)
                            handleStatusChange(
                              goalStatus === status
                                ? progress === 0
                                  ? UserTaskItemStatus.Todo
                                  : UserTaskItemStatus.InProgress
                                : status,
                              e
                            )
                        }}
                      >
                        {goalStatus === status ? selectedLabel : label}
                      </AppDropdownItem>
                    ) : null
                  )}
                </AppDropdownMenu>
              )}
            </div>
          )}
          <div className="flex flex-1 flex-col items-start gap-2">
            <p className="text-base font-medium leading-6 text-black-appDark">{title}</p>
            <p className="text-sm font-normal leading-5 text-black-appDime">{description}</p>
          </div>

          <div className="w-full flex flex-col gap-5">
            <div className="w-full flex items-center">
              <AppProgressBar
                progress={goalStatus === UserTaskItemStatus.Done ? 100 : progress || 0}
                showLabelRight={true}
                disabled={goalStatus === UserTaskItemStatus.Paused}
              />
            </div>

            <div className="w-full flex md:flex-wrap items-center justify-between">
              <AppToolTip
                name={`${goal?.name}-start-date`}
                variant="clear"
                renderContent={() =>
                  <div className="flex flex-row gap-2">
                    <p className="text-sm text-black-appDime">Start Date</p>
                    <AppBadge
                      size="sm"
                      color={'Green'}
                      label={startDate ? `${moment(startDate).format('MMMM Do, YYYY')}` : 'N/A'}
                      type={'Filled'}
                    />
                  </div>
                }
                place="bottom"
              >
                <div className="flex items-center gap-2">
                  <p className="text-sm text-black-appDime">Due Date</p>
                  <AppToolTipIcon />
                </div>
              </AppToolTip>
              {!changeCardValue.dueDate ? (
                <AppBadge
                  size="sm"
                  color={'Green'}
                  label={goalDueDate ? `${moment(goalDueDate).format('MMMM Do, YYYY')}` : 'N/A'}
                  type={'Filled'}
                  onClick={(event) => {
                    event.stopPropagation()
                    if (!isMyOtherTask) {
                      handleClick('dueDate', true)
                    }
                  }}
                />
              ) : (
                <div className="w-1/2" onClick={(event) => event.stopPropagation()}>
                  <AppTextField
                    size="sm"
                    type="date"
                    placeholder="Select Due Date"
                    value={goalDueDate}
                    onChange={(e) => {
                      setGoalDueDate(e.target.value)
                    }}
                    error={
                      goalDueDate && moment(goalDueDate).isBefore(moment(), 'day')
                        ? 'Due date cannot be in the past'
                        : ''
                    }
                    onBlur={(e) => {
                      const selectedDate = e.target.value
                      handleClick('dueDate', false)
                      if (!moment(selectedDate).isBefore(moment(), 'day')) {
                        mutate({
                          input: {
                            goalId,
                            tenantId,
                            expectedEndDate: selectedDate,
                            userId,
                          },
                        })
                      } else {
                        setGoalDueDate(expectedEndDate)
                      }
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </AppCardDetails>
      )}
      <AppCardFooter>
        {!isMyOtherTask && (
          <div className="flex justify-between items-center">
            <AppBadge size="md" type="Outline" color={'White'} label={categoryType[category]} />
            {goalStatus === UserTaskItemStatus.Done ? (
              <AppBadge size="md" color={'Green'} label={'Completed'} type={'Filled'} />
            ) : goalStatus === UserTaskItemStatus.Paused ? (
              <AppBadge size="md" type="Outline" color={'Disabled'} label={'Paused'} />
            ) : null}
          </div>
        )}
      </AppCardFooter>

      {isConfirmModalOpen && (
        <AppConfirmationModal
          title="Mark goal as done"
          isSuccess={isSuccessUpdatingGoal}
          isError={isError}
          isLoading={isLoadingGoal}
          errorMessage={error?.message}
          onClose={() => setIsConfirmModalOpen(false)}
          onConfirm={handleMarkGoalAsDone}
          description={`Marking goal as done means you have achieved the goal '${title}' and want to change the status to DONE`}
        />
      )}
    </AppCardWrapper>
  )
}

const getStatusChipColor = (priority: string): BadgeColor => {
  switch (priority) {
    case BusinessGoalPriority.HighPriority:
      return 'Red'
    case BusinessGoalPriority.MediumPriority:
      return 'Orange'
    case BusinessGoalPriority.LowPriority:
      return 'Green'
    default:
      return 'Red'
  }
}
