import React, { FC, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { AppButton } from 'components/Common/AppButton/AppButton.component'
import { AppCheckBoxSelect } from 'components/Common/AppFormFields/AppCheckBoxSelect/AppCheckBoxSelect.component'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppTextField } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import { AppNumericField } from 'components/Common/AppFormFields/AppNumericField/AppNumericField.component'
import { AppPicker } from 'components/Common/AppFormFields/AppPicker/AppPicker.component'
import { AppButtonSelect } from 'components/Common/AppFormFields/AppButtonSelect/AppButtonSelect.component'
import { AppUploadFiles } from 'components/Common/AppUpload/AppUploadFiles.component'
import ModalBox from 'components/Common/ModalBox'
import XIcon from 'components/Common/SvgIcons/XIcon'
import Label from 'components/Common/Label'
import {
  UserTask,
  UserInputField,
  useGetBusinessProfileQuery,
  useGetUserFilesQuery,
  ValidationTypes,
  FieldType,
  UserTaskItemStatus,
} from '__generated__/api-types-and-hooks'
import { AppTaskUserInputFieldsConnector } from './AppTaskUserInputFields.connector'
import { RootState } from 'App'
import { useSelector } from 'react-redux'
import { getTenantId } from 'utils/getTenantId'
import { LoadingIndicator } from 'stream-chat-react'

interface IAppTaskUserInputFieldsProps {
  task: UserTask
  isViewMode: boolean
  onSubmit: (formData: Record<string, any>) => void
  scrollToBottom?: () => void
  uploadFileAction?: (options: { files: FileList; isMultiple: boolean; saveFile: boolean }) => void
  uploadedFileId?: string
  uploadFileErrors?: any
  uploadFileLoading?: boolean
  resetUploadFileAction?: () => void
}

// Function to generate the yup validation schema based on form fields
const generateValidationSchema = (userInputFields: UserInputField[] = []) => {
  return yup.object().shape(
    userInputFields.reduce((schema, field) => {
      if (!field) return schema
      let fieldSchema

      // Determine the initial field type and base schema
      switch (field.validation?.type) {
        case ValidationTypes.Number:
          fieldSchema = yup.number().typeError(`Please enter a whole number (e.g., 10)`)
          break
        case ValidationTypes.Float:
          fieldSchema = yup.number().typeError(`Please enter a valid decimal number (e.g., 12.34)`)
          break
        case ValidationTypes.Date:
          fieldSchema = yup.date().typeError(`Please enter a valid date (e.g., 2022-12-31)`)
          break
        default:
          fieldSchema = yup.string()
      }

      if (field.fieldType !== FieldType.Input && field.fieldType !== FieldType.Date) {
        if (field.fieldType === FieldType.Checkbox && field.validation?.required?.value === true) {
          if (field.multiple) {
            fieldSchema = yup
              .array()
              .required('Please select an option')
              .min(1, 'Please select an option')
          } else {
            fieldSchema = yup.array().of(yup.string()).required('Please select an option')
          }
        } else {
          fieldSchema = yup.mixed().notRequired()
        }
      }

      // Add validation rules
      if (field.validation?.required?.value === true) {
        if (field.fieldType === FieldType.File) {
          fieldSchema = fieldSchema.test(
            'fileRequired',
            field.validation.required.message,
            (value) => {
              return value !== null && value !== undefined
            }
          )
        } else {
          fieldSchema = fieldSchema.required(field.validation.required.message)
        }
      }
      if (field.validation?.min?.value) {
        fieldSchema = fieldSchema.min(
          Number(field.validation.min.value),
          field.validation.min.message
        )
      }
      if (field.validation?.max?.value) {
        fieldSchema = fieldSchema.max(
          Number(field.validation.max.value),
          field.validation.max.message
        )
      }
      if (field.validation?.pattern?.value) {
        fieldSchema = fieldSchema.matches(
          new RegExp(String(field.validation.pattern.value)),
          field.validation.pattern.message
        )
      }
      if (field.validation?.dateMin?.value) {
        const minDate = new Date(field.validation.dateMin.value)
        fieldSchema = fieldSchema.min(minDate, field.validation.dateMin.message)
      }
      if (field.validation?.dateMax?.value) {
        const maxDate = new Date(field.validation.dateMax.value)
        fieldSchema = fieldSchema.max(maxDate, field.validation.dateMax.message)
      }

      schema[field.name] = fieldSchema
      return schema
    }, {} as Record<string, yup.AnySchema>)
  )
}

export const AppTaskUserInputFields = AppTaskUserInputFieldsConnector<
  FC<IAppTaskUserInputFieldsProps>
>(
  ({
    task,
    isViewMode,
    onSubmit,
    scrollToBottom,
    uploadFileAction,
    uploadedFileId,
    uploadFileErrors,
    uploadFileLoading,
    resetUploadFileAction,
  }) => {
    const [showUploadModal, setShowUploadModal] = useState<Record<string, boolean>>({})
    const validationSchema = generateValidationSchema(task.userInputFields ?? [])
    const userId = useSelector((state: RootState) => state.user.user.id)
    const { data: userFilesResponse, isLoading: isLoadingUserFiles } = useGetUserFilesQuery(
      {
        userId,
        tenantId: getTenantId(),
      },
      {
        refetchOnWindowFocus: false,
      }
    )
    const userFiles = userFilesResponse?.getUserFiles?.data
      ? JSON.parse(userFilesResponse?.getUserFiles?.data)
      : []
    const [currentFile, setCurrentFile] = useState<File | null>(null)
    const { data: businessProfileResponse } = useGetBusinessProfileQuery(
      {
        id: userId,
      },
      {
        refetchOnWindowFocus: false,
      }
    )
    const businessProfile = businessProfileResponse?.getBusinessProfile
    const {
      handleSubmit,
      control,
      setValue,
      formState: { errors },
    } = useForm({
      mode: 'all',
      resolver: yupResolver(validationSchema),
    })

    // Add effect to handle successful upload
    useEffect(() => {
      if (uploadedFileId && !uploadFileLoading) {
        // Find the field that has the modal open
        const openField = Object.entries(showUploadModal).find(([_, isOpen]) => isOpen)
        if (openField) {
          const [fieldName] = openField
          // Close the modal
          setShowUploadModal({ ...showUploadModal, [fieldName]: false })
          // Update form field
          setValue(fieldName, uploadedFileId)
          // Submit form
          handleSubmit(onSubmit)()
        }
      }
    }, [uploadedFileId, uploadFileLoading, showUploadModal, setValue, handleSubmit, onSubmit])

    if (!task.userInputFields?.length) return null

    return (
      <div className="flex flex-col gap-2">
        <form
          onSubmit={(e) => {
            e.preventDefault()
            handleSubmit(onSubmit)(e)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSubmit(onSubmit)(e)
            }
          }}
          className="w-full"
        >
          {task.userInputFields.map((field, index) => (
            <div key={index} className="bg-white px-6 py-3 rounded-lg w-full">
              <div className="flex flex-col gap-2 rounded-md">
                <Label
                  title={field.label}
                  fontSize="xs"
                  className="!text-base text-black-appDark !font-inter"
                >
                  {field.label}
                </Label>

                {field.fieldType === FieldType.Input && (
                  <>
                    {['number', 'float'].includes(field.validation?.type ?? '') ? (
                      <Controller
                        name={field.name}
                        control={control}
                        defaultValue={field.value ? Number(field.value) : null}
                        render={({ field: formField }) => (
                          <AppNumericField
                            type="numeric"
                            size="md"
                            disabled={task.status === UserTaskItemStatus.Done}
                            placeholder={field.placeholder || ''}
                            //   disabled={isViewMode}
                            error={errors[formField.name]?.message as string}
                            {...formField}
                          />
                        )}
                      />
                    ) : (
                      <Controller
                        name={field.name}
                        control={control}
                        defaultValue={field.value || ''}
                        render={({ field: formField }) => (
                          <AppTextField
                            type="text"
                            size="md"
                            placeholder={field.placeholder || ''}
                            disabled={task.status === UserTaskItemStatus.Done}
                            error={errors[formField.name]?.message as string}
                            {...formField}
                          />
                        )}
                      />
                    )}
                  </>
                )}

                {field.fieldType === FieldType.Checkbox && (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={field.value}
                    render={({ field: formField }) => (
                      <AppCheckBoxSelect
                        {...formField}
                        id="tile-select"
                        value={formField.value || []}
                        isDisabled={task.status === UserTaskItemStatus.Done}
                        placeholder={
                          field.placeholder
                            ? field.placeholder
                            : field.multiple
                            ? 'Please Select'
                            : (field.options?.length as number) > 1
                            ? 'Please Select One'
                            : ''
                        }
                        disableSearch={(field.options?.length as number) <= 1}
                        onChange={(input) => {
                          const data = Array.isArray(input) ? input : [input]
                          formField.onChange(data)
                        }}
                        options={
                          field.options?.map((opt) => ({
                            label: opt.label,
                            value: opt.value,
                          })) ?? []
                        }
                        error={errors[formField.name]?.message as string}
                        isMulti={!!field.multiple}
                        //   isDisabled={isViewMode}
                      />
                    )}
                  />
                )}

                {field.fieldType === FieldType.ButtonSelect && (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={field.value}
                    render={({ field: formField }) => (
                      <AppButtonSelect
                        {...formField}
                        id="tile-select"
                        isDisabled={task.status === UserTaskItemStatus.Done}
                        value={formField.value || []}
                        onChange={(input) => {
                          formField.onChange(input)
                          handleSubmit(onSubmit)()
                        }}
                        options={
                          field.options?.map((opt) => ({
                            label: opt.label,
                            value: opt.value,
                          })) ?? []
                        }
                        //   isDisabled={isViewMode}
                      />
                    )}
                  />
                )}

                {field.fieldType === FieldType.Select && (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={
                      field.value ?? (field.options?.length ? field.options[0]?.value : null)
                    }
                    render={({ field: formField }) => (
                      <div onClick={scrollToBottom}>
                        <AppSelectField
                          disabled={task.status === UserTaskItemStatus.Done}
                          options={field.options ?? []}
                          size="sm"
                          {...formField}
                          onChange={(e) => {
                            formField.onChange(e.value)
                          }}
                        />
                      </div>
                    )}
                  />
                )}

                {field.fieldType === FieldType.Date && (
                  <Controller
                    name={field.name}
                    control={control}
                    render={({ field: formField }) => (
                      <>
                        <AppPicker
                          type={'date'}
                          {...formField}
                          onChange={(e) => {
                            formField.onChange(e)
                          }}
                          isdisable={task.status === UserTaskItemStatus.Done}
                          minDate={field.validation?.dateMin?.value || undefined}
                        />
                        {errors[formField.name] && (
                          <p style={{ color: 'red' }}>{errors[formField.name]?.message}</p>
                        )}
                      </>
                    )}
                  />
                )}

                {field.fieldType === FieldType.File && (
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={
                      field.value ||
                      businessProfile?.[field.destinationResource?.field as string]?.name
                    }
                    render={({ field: formField }) => {
                      const file = userFiles.find((file: any) => file.id === formField.value)
                      return (
                        <div className="flex flex-col gap-2">
                          <div className="flex items-center gap-4">
                            <AppButton
                              label={formField.value ? 'Change File' : 'Upload File'}
                              variant="secondary"
                              type="button"
                              disabled={task.status === UserTaskItemStatus.Done}
                              size="sm"
                              onClick={() =>
                                setShowUploadModal({ ...showUploadModal, [field.name]: true })
                              }
                            />
                            {formField.value && (
                              <span className="text-sm text-gray-600">
                                {isLoadingUserFiles ? (
                                  <LoadingIndicator size={16} />
                                ) : (
                                  currentFile?.name ||
                                  file?.name ||
                                  businessProfile?.[field.destinationResource?.field as string]
                                    ?.name
                                )}
                              </span>
                            )}
                          </div>
                          {showUploadModal[field.name] && (
                            <ModalBox
                              onClose={() => {
                                setShowUploadModal({ ...showUploadModal, [field.name]: false })
                                resetUploadFileAction?.()
                              }}
                              title="File Upload"
                              Icon={XIcon}
                            >
                              <AppUploadFiles
                                isMultiple={false}
                                showUploadModal={showUploadModal[field.name]}
                                setShowUploadModal={(show) =>
                                  setShowUploadModal({ ...showUploadModal, [field.name]: show })
                                }
                                disableButton={task.status === UserTaskItemStatus.Done}
                                uploadedFile={
                                  currentFile
                                    ? [{ url: formField.value, name: currentFile.name }]
                                    : formField.value
                                    ? [{ url: formField.value }]
                                    : []
                                }
                                uploadFileErrors={uploadFileErrors}
                                resetUploadFileAction={resetUploadFileAction}
                                uploadFileLoading={uploadFileLoading}
                                isBorderVariantChanged={true}
                                getFiles={(files) => {
                                  if (uploadFileAction && files?.[0]) {
                                    setCurrentFile(files[0])
                                    uploadFileAction({
                                      files: files as unknown as FileList,
                                      isMultiple: false,
                                      saveFile: true,
                                    })
                                  }
                                }}
                                saveFile={() => {
                                  setShowUploadModal({ ...showUploadModal, [field.name]: false })
                                }}
                              />
                            </ModalBox>
                          )}
                          {errors[field.name] && (
                            <p className="text-red-500 text-sm">{errors[field.name]?.message}</p>
                          )}
                        </div>
                      )
                    }}
                  />
                )}
              </div>
            </div>
          ))}

          {
            <div className="flex justify-end mt-4">
              <AppButton
                label="Submit"
                size="md"
                variant="primary"
                disabled={!isEmpty(errors) || task.status === UserTaskItemStatus.Done}
                RightIcon={isEmpty(errors) ? undefined : undefined}
                isFullWidth={false}
                onClick={handleSubmit(onSubmit)}
              />
            </div>
          }
        </form>
      </div>
    )
  }
)
