import React from 'react'

import {
  AppStyledAvatarWrapper,
  AppStyledDotWrapper,
  AppAvatarWrapper,
} from 'components/Common/AppAvatar/AppAvatar.style'
import { AppStatusDot } from 'components/Common//AppStatusDot/AppStatusDot.component'

export type ColorType = 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange'
export interface AppAvatarProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  type: 'image' | 'text' | 'mixed'
  shape: 'Squared' | 'Rounded'
  status?: boolean
  color?: ColorType
  src?: string
  text?: string
  textStyles?: string
}

export const AppAvatar: React.FC<AppAvatarProps> = ({
  size,
  type,
  shape,
  status,
  color,
  src,
  text,
  textStyles,
}) => (
  <AppAvatarWrapper>
    <AppStyledAvatarWrapper size={size} shape={shape} color={color}>
      {type === 'image' && <img src={src} alt="avatar" />}
      {type === 'text' && text}
      {type === 'mixed' && (
        <>
          {src ? (
            <img src={src} alt="avatar" />
          ) : (
            <span
              className={`rounded-full inline-flex justify-center items-center text-white font-semibold uppercase bg-tertiary w-8 h-8 text-xs ${textStyles}`}
            >
              {text}
            </span>
          )}
        </>
      )}
      {status && (
        <AppStyledDotWrapper>
          <AppStatusDot color={color ?? 'Green'} size={size} outline />
        </AppStyledDotWrapper>
      )}
    </AppStyledAvatarWrapper>
  </AppAvatarWrapper>
)
