import React, { FC, useState } from 'react'
import { includes } from 'lodash'

import { AppErrorMessage } from 'components/Common/AppFormFields/AppTextField/AppTextField.component'
import {
  AppMenuContainerWrapper,
  AppMenuInputWrapper,
  AppMenuWrapper,
} from 'components/Common/AppMenu/AppMenu.style'
import { AppAvatar, ColorType } from 'components/Common/AppAvatar/AppAvatar.component'
import { AppChevronDownIcon } from 'components/Common/AppSvgIcons/AppChevronDownIcon'
import appColors from 'appColors'
import { useClickOutside } from 'hooks/AppuseClickOutside/useClickOutside.hook'
import { AppCheckBox } from '../AppFormFields/AppCheckBox/AppCheckBox.component'

interface IAppMenuProps {
  value: string[]
  options: {
    value: string
    label: string
    description?: string
    avatar?: string // URL for avatar image
    initials?: string
    color?: ColorType
  }[]
  placeholder: string
  onChange: (input: string[]) => void
  isDisabled?: boolean
  id?: string
  error?: string
  disableSearch?: boolean
  isSelectedAll?: boolean
}
export const AppMenu: FC<IAppMenuProps> = ({
  value,
  options,
  onChange,
  placeholder,
  isDisabled = false,
  error,
  disableSearch = false,
  isSelectedAll = false,
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // Handle clicks outside the menu to close it
  const divRef = useClickOutside(() => setIsMenuOpen(false))

  const handleSelect = (optionValue: string) => {
    if (isDisabled) return
    if (optionValue === 'allMyOptions' || value.includes('allMyOptions')) {
      onChange([optionValue])
      return
    }

    const currentValue = value ?? []
    const isSelected = currentValue.includes(optionValue)

    const newValue = isSelected
      ? currentValue.filter((item) => item !== optionValue)
      : [...currentValue, optionValue]
    onChange(newValue)
  }

  const handleClose = (event) => {
    if (!isDisabled) {
      event.stopPropagation()
      setIsMenuOpen(!isMenuOpen)
    }
  }

  const filteredOptions = options.filter((option) =>
    option.label?.toLowerCase()?.includes(searchTerm.toLowerCase())
  )

  const selectedOptions = options.filter((option) =>
    includes(Array.isArray(value) ? value : [value], option.value)
  )

  return (
    <div ref={divRef} className="relative">
      <div
        className={` px-4 py-2 rounded-md flex items-center gap-2 flex-wrap w-full `}
        onClick={() => !isDisabled && setIsMenuOpen(false)}
      >
        {selectedOptions.length > 0 && !value.includes('allMyOptions') ? (
          <div onClick={handleClose}>
            <div className="flex flex-row items-center flex-wrap gap-4">
              {selectedOptions.map((option, index) => (
                <div
                  key={index}
                  className="flex flex-row items-center gap-2 px-2 py-1 bg-gray-100 rounded-md cursor-pointer"
                >
                  {option.initials && (
                    <AppAvatar
                      size={'sm'}
                      type={'text'}
                      shape={'Rounded'}
                      color={option.color}
                      text={option.initials}
                    />
                  )}
                  <span className="text-gray-800 text-sm">{option.label}</span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full" onClick={handleClose}>
            <div className="w-full text-app-grey-60 flex items-center justify-between cursor-pointer">
              <div>{placeholder}</div>
              <AppChevronDownIcon color={appColors['app-grey']['60']} />
            </div>
          </div>
        )}
      </div>

      {/* Dropdown menu */}
      {isMenuOpen && (
        <AppMenuWrapper className=" !bg-app-white-100 mt-2 bg-white border border-gray-300 rounded-md shadow-lg w-full absolute z-50">
          {!disableSearch && (
            <AppMenuInputWrapper
              type="text"
              disabled={disableSearch}
              placeholder="Search name or group"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="px-4 py-2 border-b border-gray-300"
            />
          )}

          {filteredOptions.map((option, index) => {
            const isSelected = includes(Array.isArray(value) ? value : [value], option.value)
            return (
              <AppMenuContainerWrapper
                key={index}
                isSelected={isSelected}
                onClick={() => handleSelect(option.value)}
                className={`flex items-center gap-4 p-4 cursor-pointer ${
                  isSelected ? 'bg-gray-200' : 'hover:bg-gray-100'
                }`}
              >
                <AppCheckBox value={isSelected} />
                {option.initials && (
                  <AppAvatar
                    size={'sm'}
                    type={'text'}
                    shape={'Rounded'}
                    color={option.color}
                    text={option.initials}
                  />
                )}
                <div className="flex flex-col">
                  <span className={`font-medium ${isSelected ? 'text-black' : 'text-gray-800'} `}>
                    {option.label}
                  </span>
                  {option.description && (
                    <span className="text-sm text-gray-500">{option.description}</span>
                  )}
                </div>
              </AppMenuContainerWrapper>
            )
          })}

          {error && <AppErrorMessage error={error} />}
        </AppMenuWrapper>
      )}
    </div>
  )
}
