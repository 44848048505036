import { useDispatch } from 'react-redux'
import { getUserProfileAction } from 'store/actions/user'

const useGetUserProfile = () => {
  const dispatch = useDispatch()

  const getUserProfile = () => {
    dispatch(getUserProfileAction.STARTED())
  }

  return getUserProfile
}

export default useGetUserProfile
