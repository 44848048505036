import React from 'react'
import ReactPlayer from 'react-player'

export const AppVideoPlayer = ({
  url,
  playing = false,
  controls = true,
  width = '100%',
  height = '400px',
}) => {
  return (
    <div className="video-player">
      <ReactPlayer url={url} playing={playing} controls={controls} width={width} height={height} />
    </div>
  )
}
