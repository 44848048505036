import tw from 'twin.macro'
import styled from 'styled-components'

export const AppGoalCenterWrapper = styled.div`
  ${tw`w-full max-w-full flex flex-col gap-2 mb-4`}
`
export const AppGoalCenterHeading = styled.h1`
  ${tw`font-medium font-inter text-[32px] leading-9	`}
`
export const AppGoalCenterSubHeading = styled.p`
  ${tw`font-normal font-inter font-normal text-sm text-app-grey-75`}
`
export const AppActiveGoalsWrapper = styled.div`
  ${tw`flex flex-col gap-8 w-full max-w-full`}
`

export const AppActiveGoalsHeading = styled.h1`
  ${tw`font-bold font-primary text-2xl mb-4`}
`

export const AppActiveGoalsGrid = styled.div`
  ${tw`grid xs:grid-cols-1 sm:grid-cols-12 xs:gap-8 lg:gap-12`}
`

export const AppNoGoalsMessage = styled.div`
  ${tw`h-60 flex justify-center items-center text-lg text-grey`}
`

export const AppFilterHeader = styled.div`
  ${tw`lg:flex lg:flex-row space-y-6 lg:space-y-0 sm:justify-between sm:items-start w-full`}
`

export const AppFiterHeadingWrapper = styled.div`
  ${tw`flex flex-col gap-2 `}
`

export const AppFilterButtonWrapper = styled.div`
  ${tw`flex relative xs:flex-col sm:flex-row sm:items-start gap-4`}
`

export const AppCountCardWrapper = styled.div`
  ${tw`flex gap-7 flex-wrap lg:flex-nowrap mb-4`}
`

export const AppCard = styled.div`
  ${tw`relative flex h-44 w-full gap-7 p-6 overflow-hidden rounded-2xl bg-gradient-to-r from-primary-appBrand to-app-primary-65 border border-app-primary-55 shadow-[0px_16px_32px_-16px_#00000014]`}
`

export const AppContent = styled.div`
  ${tw`flex flex-col gap-2 flex-[1.6]`}
`
export const AppContentLabel = styled.h4`
  ${tw`text-white font-inter text-3xl sm:leading-[56px] md:leading-[64px] lg:text-4xl lg:leading-[56px] font-bold leading-10 self-stretch`}
`

export const AppCountWrapper = styled.div`
  ${tw`relative flex-1`}
`

export const AppCountBubble = styled.div`
  ${tw` relative -bottom-4 xs:right-1 lg:right-2 shadow-white shadow-[0px_2px_45px_0px_rgba(255,255,255,1)] px-5 py-9 w-[165px] h-[165px] flex flex-col items-center justify-center border border-app-primary-25 rounded-4xl bg-gradient-to-r from-app-primary-75 from-[4%] to-[96%] to-app-primary-95 rotate-6 shadow-lg`}
`

export const AppCount = styled.span`
  ${tw`text-white font-bold font-inter text-[90px]`}
`

export const Divider = styled.hr`
  ${tw`w-full border border-background-hover mb-4`}
`
