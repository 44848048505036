import React from 'react'

export const AppCheckIcon: React.ComponentType<{
  color?: string
}> = ({ color = '#61BA47' }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="check">
        <path
          id="Shape"
          d="M3.75 10.625L8.75 15.625L16.25 4.375"
          stroke={color}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  )
}
