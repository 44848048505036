import React from 'react'

export const AppSendMessageIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.99989 10L2.72412 2.60376C8.23673 4.20514 13.3564 6.73 17.9047 9.99978C13.3565 13.2696 8.23679 15.7945 2.7242 17.396L4.99989 10ZM4.99989 10L11.2501 10"
        stroke="white"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

AppSendMessageIcon.defaultProps = {
  className: '',
}
