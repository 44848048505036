import styled from 'styled-components'
import tw from 'twin.macro'
export const AppStyledDotWrapper = styled.div<{
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
  color: 'Grey' | 'Primary' | 'Red' | 'Green' | 'Orange'
  outline?: boolean
}>`
  ${tw`rounded-full -ml-2`}

  ${({ size }) => size === 'xs' && tw`w-[6px] h-[6px]`}
  ${({ size }) => size === 'sm' && tw`w-[8px] h-[8px]`}
  ${({ size }) => size === 'md' && tw`w-[10px] h-[10px]`}
  ${({ size }) => size === 'lg' && tw`w-[12px] h-[12px]`}
  ${({ size }) => size === 'xl' && tw`w-[16px] h-[16px]`}
  ${({ size }) => size === '2xl' && tw`w-[18px] h-[18px]`}
  ${({ size }) => size === '3xl' && tw`w-[20px] h-[20px]`}

  ${({ color }) => color === 'Grey' && tw`bg-app-grey-60`}
  ${({ color }) => color === 'Primary' && tw`bg-app-primary-70`}
  ${({ color }) => color === 'Red' && tw`bg-app-red-65`}
  ${({ color }) => color === 'Green' && tw`bg-app-green-65`}
  ${({ color }) => color === 'Orange' && tw`bg-app-orange-65`}
  
  box-shadow: ${({ outline }) => (outline ? '0px 0px 0px 2px white' : 'none')};
  z-index: 100;
`
