import { useMemo } from 'react'
import { PathNames } from 'config'
import history from 'utils/history'
import NAVIGATION from 'routes/navigation'
import { useParams } from 'react-router-dom'
import { copy, getRoleFromPath } from 'utils/helper'
import { RootState } from 'App'
import { isUndefined } from 'lodash'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'hooks/userFeatureFlag'

const useNavigationItems = () => {
  const params = useParams()
  const tenantId = useMemo(() => params?.tenantId, [params])
  const path = history.location.pathname
  const userId = useSelector((state: RootState) => state.user.user.id)
  const isFeatureFlagForDataTabDisabled = useFeatureFlag(userId, 'release-data-tab')

  const items: any[] = useMemo(() => {
    const role = getRoleFromPath()
    if (!role || !(role in NAVIGATION)) return []
    return NAVIGATION[role]
      .filter(
        (item) =>
          !(
            item.label === 'Data' &&
            (isFeatureFlagForDataTabDisabled || isUndefined(isFeatureFlagForDataTabDisabled))
          )
      )
      .map((it) => {
        const item = copy(it)
        if (item.path && tenantId) {
          item.path = item.path.replace(':tenantId', tenantId)
        }

        return {
          ...item,
          selected: path.includes(PathNames[item.label]),
        }
      })
  }, [path, tenantId, isFeatureFlagForDataTabDisabled])

  return items
}

export default useNavigationItems
