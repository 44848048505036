import React from 'react'
import { CheckboxWrapper } from './AppCheckBox.style'
import { AppToolTip } from 'components/Common/AppToolTip/AppToolTip.component'
import { AppToolTipIcon } from 'components/Common/AppToolTip/AppToolTipIcon'

interface AppCheckBoxProps {
  value: boolean
  label?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  id?: string
  variant?: 'tick' | 'indeterminate'
  onBlur?: (value) => void
  toolTipText?: string
}

export const AppCheckBox: React.FC<AppCheckBoxProps> = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  label,
  id,
  variant = 'tick',
  toolTipText,
}) => {
  return (
    <div className="inline-flex items-baseline gap-2 relative">
      <CheckboxWrapper
        id={id}
        onChange={onChange}
        type="checkbox"
        checked={value}
        disabled={disabled}
        className={`${
          value
            ? variant === 'indeterminate'
              ? "!bg-[url('assets/svgs/minus-small.svg')]"
              : "!bg-[url('assets/svgs/WhiteTick.svg')]"
            : ''
        }`}
        onBlur={onBlur}
      />
      {label && (
        <div className="text-black font-regular text-base font-normal tracking-normal leading-normal mt-[-1px] relative w-fit break-words">
          {label}
        </div>
      )}
      {toolTipText && (
        <div className="self-start">
          <AppToolTip name="ToolTip" text={toolTipText} variant="primary" place="bottom">
            <AppToolTipIcon />
          </AppToolTip>
        </div>
      )}
    </div>
  )
}
