import React, { useEffect, useState } from 'react'
import uuid from 'react-uuid'

import { getFileIcon, checkFileTypeHandler } from 'components/Common/AppDropzone/AppDropzone.utils'

import Button from 'components/Common/Button'
import { AppDropzone } from 'components/Common/AppDropzone/AppDropzone.component'
import { EllipseIcon } from 'components/Common/SvgIcons'
import CheckMark from 'components/Common/SvgIcons/CheckMark'
import { formatFileNameWithExtension } from 'utils/helper'
import ErrorIcon from 'components/Common/SvgIcons/ErrorIcon'
import { CustomFile } from 'types'
import CircularLoading from 'components/Common/CircularLoading'

export const AppUploadFiles = ({
  isMultiple,
  uploadType,
  uploadedFile,
  fileExtensions,
  showUploadModal,
  showCloseButton,
  uploadFileErrors,
  setShowUploadModal,
  disableButton,
  resetUploadFileAction,
  uploadFileLoading,

  isBorderVariantChanged,
  getFiles,
  saveFile,
}) => {
  const [files, setFiles]: any = useState([])

  useEffect(() => {
    return () => resetUploadFileAction()
  }, [resetUploadFileAction])

  const handleChange = (file: CustomFile[]) => {
    let isFileSupported = true
    const customFile = [...file]
    if (uploadType) {
      let { isError } = checkFileTypeHandler(uploadType, customFile, fileExtensions || [])
      if (isError) {
        customFile[0].invalid = true
        isFileSupported = false
      }
    }
    if (isFileSupported) {
      getFiles(customFile)
    }
    setFiles([...customFile, ...files])
  }

  return (
    <>
      <div className="grid xs:px-6 sm:pl-10 sm:pr-6 xs:grid-cols-1 xs:pt-8 xs:pb-7 sm:grid-cols-12 sm:pt-14 sm:pb-7">
        <AppDropzone
          handleChange={handleChange}
          isMultiple={isMultiple}
          uploadType={uploadType}
          fileExtensions={fileExtensions}
          uploadFileLoading={uploadFileLoading}
          isBorderVariantChanged={isBorderVariantChanged}
        />
        <div className="xs:block sm:flex sm:flex-col justify-between items-end col-span-6 xs:px-0 sm:px-6 relative">
          <div
            className={`h-auto xs:max-h-[40vh] xs:overflow-y-auto sm:h-[54vh] sm:max-h-[80%] xs:px-5 w-full`}
          >
            {files &&
              files.map((file, index) => {
                const icon = getFileIcon(file.name)
                const imageIndex = files.length - index - 1
                return (
                  <div
                    className="flex items-start py-3 border rounded-xl border-primary-outline xs:mb-4 sm:mb-6"
                    key={uuid()}
                  >
                    <span className="mx-3 pt-1 relative">
                      <EllipseIcon className={'fill-primary-outline h-8 w-8'} />
                      {icon && (
                        <icon.value className={'absolute top-[9px] left-[8px] w-4 h-auto'} />
                      )}
                    </span>
                    <span className="flex flex-col w-full pr-8 text-ellipsis overflow-hidden">
                      <span className="text-base line-clamp-1 overflow-hidden">
                        {formatFileNameWithExtension(file.name)}
                      </span>
                      <span className="text-silver-darker text-base">
                        <div className={` my-1`}>
                          {file.invalid ? (
                            <span className="inline">
                              <ErrorIcon className="fill-primary-error inline mr-1" />
                              <label className="font-primary text-sm font-bold text-center text-primary-error">
                                Upload Error: File not supported
                              </label>
                            </span>
                          ) : !uploadedFile[imageIndex]?.url ? (
                            <div className="flex items-center gap-2">
                              <CircularLoading />
                              <span className="font-primary text-silver-darker text-sm font-normal">
                                In Progress
                              </span>
                            </div>
                          ) : (
                            <span className="sm:flex sm:items-center">
                              <CheckMark className="fill-green inline mr-1 w-[13.46px] h-[9.9px]" />
                              <label className="font-primary text-silver-darker text-sm font-normal">
                                Complete
                              </label>
                            </span>
                          )}
                        </div>
                        {/* {uploadedImage && isArray(uploadedImage) && !uploadedImage[index] && (
                          <span className="inline">
                            <ErrorIcon className="fill-primary-error inline mr-1" />
                            <label className="font-primary text-sm font-normal text-primary-error">
                              Upload Error: Upload failed
                            </label>
                          </span>
                        )} */}

                        {uploadFileErrors && (
                          <span className="inline">
                            <ErrorIcon className="fill-primary-error inline mr-1" />
                            <label className="font-primary text-sm font-normal text-primary-error">
                              Upload Error: {uploadFileErrors}
                            </label>
                          </span>
                        )}
                      </span>
                    </span>
                    {/* Will use this later */}
                    {/* <span className="absolute right-8 top-5 cursor-pointer">
                    <CrossIcon className=" w-4 h-4 " />
                  </span> */}
                  </div>
                )
              })}
          </div>
          {showCloseButton && (
            <div className="flex justify-end gap-4 xs:pt-6 xs:static xs:bottom-8 sm:static sm:bottom-0 right-8">
              <div>
                {/* check-button - clean up custom dimensions */}
                <Button
                  className="w-full xs:w-[143px] xs:h-16 !text-2xl sm:h-16 sm:py-4 sm:px-8"
                  label="Cancel"
                  onClick={(e) => setShowUploadModal(false, e)}
                  variant="secondary"
                  disabled={disableButton || uploadFileLoading}
                />
              </div>
              <div>
                {/* check-button - clean up custom dimensions */}
                <Button
                  className="w-full xs:w-[125px] xs:h-16 !text-2xl sm:h-16 sm:py-4 sm:px-8"
                  label="Done"
                  // onClick={(e) => uploadFile(e)}
                  onClick={(e) => saveFile(e)}
                  variant="primary"
                  disabled={disableButton || uploadFileLoading}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
AppUploadFiles.defaultProps = {
  showCloseButton: true,
  uploadType: '',
  fileExtensions: [],
  isMultiple: true,
  isBorderVariantChanged: true,
  disableButton: false,
  uploadFileLoading: false,
  resetUploadFileAction: () => {},
  saveUserPlayFileAction: () => {},
  userId: '',
  isPlay: false,
  uploadedFile: {},
  saveFile: () => {},
}
