import { includes, last, findKey, isEmpty } from 'lodash'
import { fileIcons, defaultFileIcon, fileExtension, UPLOAD_TYPE } from 'config'

export const getExt = (fileName: string) => {
  return last(fileName?.split('.')) || ''
}

const getFileIcons = (fileType) =>
  fileIcons.find((icons) => {
    return includes(icons.iconLabel, fileType)
  })

export const getFileIcon = (fileName: string) => {
  const ext = getExt(fileName)
  if (ext) {
    const fileType = findKey(fileExtension, (allExts) => includes(allExts, ext))
    return getFileIcons(fileType) || defaultFileIcon
  }
  return defaultFileIcon
}

export const checkFileType = (uploadedFileExtension) => {
  const isImage = fileExtension.image.some((type) => uploadedFileExtension.includes(type))
  return isImage
}

export const checkFileTypeHandler = (uploadType, fileData, fileExtensions) => {
  let isValid: boolean = false
  const uploadedFileExtension: string = getExt(fileData[0]?.name)

  if (uploadType === UPLOAD_TYPE.IMAGE) {
    isValid = checkFileType(uploadedFileExtension)
  } else if (uploadType !== UPLOAD_TYPE.IMAGE) {
    let fileType = fileExtensions ? fileExtensions : [...fileExtension.word, ...fileExtension.pdf]
    if (Array.isArray(fileType) && !isEmpty(fileType)) {
      isValid = fileType.some((type) => uploadedFileExtension.includes(type))
    }
  }
  return { isError: !isValid, uploadedFile: isValid ? fileData[0] : null }
}
