export enum TaskFormFields {
  name = 'name',
  status = 'status',
  estimatedEffortInMinutes = 'estimatedEffortInMinutes',
  taskPriority = 'taskPriority',
  category = 'category',
  assigneeId = 'assigneeId',
  startDate = 'startDate',
  expectedEndDate = 'expectedEndDate',
  instructionForTask = 'instructionForTask',
  goalId = 'goalId',
}
