import styled from 'styled-components'
import tw from 'twin.macro'

export const AppCalendarWrapper = styled.div`
  ${tw`w-[344px] p-6 pt-6 bg-white rounded-2xl border border-solid border-app-grey-30 shadow-xl flex flex-col gap-4`}

  .react-calendar {
    ${tw`w-full border-0`}
  }

  .react-calendar__navigation {
    ${tw`w-[296px] h-[32px] flex justify-between items-center`}
    gap: 0;
  }

  .react-calendar__navigation__label {
    ${tw`font-medium font-inter text-sm leading-5 hover:!bg-transparent hover:!text-app-black-100 !text-app-grey-70`}
  }
  .react-calendar__navigation__label span {
    color: rgba(94, 94, 94, 1);

    &:hover {
      color: black;
    }
  }

  .react-calendar__month-view__weekdays__weekday abbr:where([title]) {
    ${tw`font-normal font-inter text-sm capitalize no-underline	 leading-5 hover:!bg-transparent hover:!text-app-black-100 !text-app-grey-50`}

    color: rgba(94, 94, 94, 1);
    &:hover {
      color: black;
    }
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    ${tw`text-black-appDark text-base  flex items-center justify-center shadow-sm rounded-lg p-1.5 min-w-[32px] min-h-[32px] !border border-solid !border-primary-appDarkBorder`}
  }

  .react-calendar__tile {
    ${tw`flex justify-center items-center mx-[5px] max-w-[32px] h-[32px] p-1.5 gap-2 rounded-lg `}
  }

  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    ${tw`gap-x-2 gap-y-3`}
  }

  .react-calendar__century-view__decades {
    ${tw`justify-around`}
  }

  .react-calendar__decade-view__years .react-calendar__tile {
    ${tw`w-full h-[32px] max-w-[65px] mx-0`}

    &--hasActive, &:hover {
      ${tw` text-app-white-100 bg-app-primary-60`}
    }
  }

  .react-calendar__navigation button:disabled,
  .react-calendar__navigation button:enabled:focus {
    ${tw`!bg-transparent`}
  }

  .react-calendar__century-view__decades .react-calendar__tile {
    ${tw`w-full h-[32px] min-w-max mx-0`}

    &--hasActive, &:hover {
      ${tw` text-app-white-100 bg-app-primary-60`}
    }
  }

  .react-calendar__viewContainer:has(.react-calendar__year-view) {
    ${tw`flex flex-col items-center justify-center `}
  }

  .react-calendar__year-view__months {
    ${tw`gap-x-1 gap-y-5 justify-between`}
  }

  .react-calendar__year-view__months .react-calendar__tile {
    ${tw`w-full h-[32px] max-w-[90px] mx-0`}

    &--hasActive, &:hover {
      ${tw` !text-app-white-100 bg-app-primary-60`}

      & abbr {
        ${tw`text-app-white-100 bg-app-primary-60`}
      }
    }
  }

  .react-calendar__tile abbr {
    ${tw`w-full h-full flex justify-center items-center font-inter text-sm font-normal text-app-black-100`}
  }

  .react-calendar__month-view__days__day--neighboringMonth abbr {
    ${tw` text-app-grey-50`}
  }

  .react-calendar__tile--active abbr {
    ${tw`!bg-app-primary-60 !text-white`}
  }

  .react-calendar__tile--active {
    ${tw`!bg-app-primary-60`}

    &:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
      ${tw`!bg-app-primary-20`}

      & abbr {
        ${tw`!bg-app-primary-20 text-app-primary-60`}
      }
    }
  }

  .react-calendar__tile:hover abbr,
  .react-calendar__tile:focus abbr {
    ${tw`bg-app-primary-60 text-white`}
  }

  .react-calendar__tile--now {
    ${tw`bg-app-grey-30`}

    & abbr {
      ${tw`bg-app-grey-30 text-app-black-100`}
    }
  }

  .react-calendar button:enabled:hover {
    ${tw`bg-app-grey-30`}
  }

  .react-calendar__tile--range .react-calendar__month-view__days__day {
    ${tw`bg-app-primary-20 text-app-primary-60`}

    & abbr {
      ${tw`bg-app-primary-20 text-app-primary-60`}
    }
  }

  .react-calendar__month-view__days {
    ${tw`gap-y-3`}
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__tile.react-calendar__month-view__days__day:disabled,
  .react-calendar__tile.react-calendar__year-view__months__month:disabled,
  .react-calendar__tile.react-calendar__decade-view__years__year:disabled,
  .react-calendar__tile.react-calendar__century-view__decades__decade:disabled {
    ${tw`!bg-transparent !text-app-grey-50`}
    &:hover {
      ${tw`!bg-transparent !text-app-grey-50`}
      & abbr {
        ${tw`!bg-transparent !text-app-grey-50`}
      }
    }
    & abbr {
      ${tw`!bg-transparent !text-app-grey-50`}
    }
  }
  .react-calendar__tile.react-calendar__month-view__days__day,
  .react-calendar__tile.react-calendar__year-view__months__month,
  .react-calendar__tile.react-calendar__decade-view__years__year,
  .react-calendar__tile.react-calendar__century-view__decades__decade {
    &:hover {
      ${tw`!bg-app-primary-60 !text-white`}
    }
  }
`
