import React from 'react'
import get from 'lodash/get'
import moment from 'moment'
import { isEmpty } from 'lodash'

const PlatformInfo = ({ profile }) => {
  return (
    <>
      {!isEmpty(profile.firstSigninDate) ? (
        <div className="flex items-center space-x-4 lg:space-x-14 xl:space-x-20">
          <div className="space-y-2">
            <h3 className="text-base font-medium"> Platform Start Date: </h3>
            <h5 className="text-xl font-bold">
              {profile?.firstSigninDate
                ? moment(profile?.firstSigninDate).format('MMM Do, YYYY')
                : ''}
            </h5>
          </div>
          <div className="space-y-2">
            <h3 className="text-base font-medium">Total Days: </h3>
            <h5 className="text-xl font-bold">{get(profile, 'totalDays', 0)}</h5>
          </div>
        </div>
      ) : (
        <>
          <div className="text-sm font-normal "> Invited date </div>
          <div className=" text-2xl font-normal">
            {profile?.createdAt ? moment(profile?.createdAt).format('MMM D, YYYY') : ''}
          </div>
        </>
      )}
    </>
  )
}

export default PlatformInfo
