import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { getColorForGoalPriority } from 'appUtils'
import { taskPriorityLabelsValues, taskPriorityStatus } from 'appConfig/data'
import { AppSelectField } from 'components/Common/AppFormFields/AppSelectField/AppSelectField.component'
import { AppBadge } from 'components/Common/AppBadge/AppBadge.component'
import { FieldLabel } from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { TaskFormFields } from 'components/AppKanbanBoard/config'

interface IAppTaskPriorityFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
}

export const AppTaskPriorityField: React.FC<IAppTaskPriorityFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
}) => {
  const priorityWrapperRef = useRef<HTMLDivElement | null>(null)

  const {
    control,
    formState: { errors },
  } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        priorityWrapperRef.current &&
        !priorityWrapperRef.current.contains(event.target as Node)
      ) {
        handleClick(TaskFormFields.taskPriority, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  return (
    <>
      <FieldLabel>Priority </FieldLabel>
      <div className="col-span-7 font-inter text-black-dark">
        <Controller
          name={TaskFormFields.taskPriority}
          control={control}
          render={({ field }) => (
            <>
              {clickedFields.taskPriority ? (
                <div className="flex items-center w-2/3" ref={priorityWrapperRef}>
                  <AppSelectField
                    size="sm"
                    {...field}
                    options={taskPriorityLabelsValues}
                    placeholder="Select priority"
                    errors={errors.taskPriority?.message as string}
                    onBlur={() => handleClick(field.name, false)}
                    onChange={(e) => {
                      field.onChange(e.value)
                      handleClick(field.name, false)
                    }}
                  />
                </div>
              ) : (
                <div onClick={() => handleClick(field.name, true)}>
                  {field.value ? (
                    <AppBadge
                      size="sm"
                      type="Filled"
                      color={getColorForGoalPriority(field.value)}
                      label={taskPriorityStatus[field.value]}
                    />
                  ) : (
                    <div className="!font-inter text-black-dark">Select priority</div>
                  )}
                </div>
              )}
            </>
          )}
        />
      </div>
    </>
  )
}
