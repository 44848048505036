import React, { Dispatch, SetStateAction } from 'react'
import { FieldValues, UseFormReturn, UseFormSetValue } from 'react-hook-form'

import {
  InformationGrid,
  FieldLabel,
  UserName,
} from 'components/AppKanbanBoard/AppTask/AppTask.style'
import { AppTaskDateFields } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskDateFields/AppTaskDateFields.component'
import { UserTask } from '__generated__/api-types-and-hooks'
import { AppHourEstimationField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppHourEstimationField/AppHourEstimationField.component'
import { AppTaskNameField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskNameField/AppTaskNameField.component'
import { AppTaskStatusField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskStatusField/AppTaskStatusField.component'
import { AppTaskPriorityField } from 'components/AppKanbanBoard/AppTask/AppTaskFields/AppTaskPriorityField/AppTaskPriorityField.component'
import { AppTaskCategoryField } from './AppTaskCategoryField/AppTaskCategoryField.component'
import { AppTaskAssigneeField } from './AppTaskAssigneeField/AppTaskAssigneeField.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import { AppTaskGoalField } from './AppTaskGoalField/AppTaskGoalField.component'
import Avatar from 'appAssets/appImages/Avatar.png'
import { AppAvatar } from 'components/Common/AppAvatar/AppAvatar.component'
import { getInitials } from 'components/Chat/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'App'

interface AppTaskFieldsProps {
  methods: UseFormReturn
  task?: UserTask
  userId: string
  logo?: string
  advisorsData: Array<{ label: any; value: any }>
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
  isViewMode: boolean
  userName?: string
  noneEditableFields?: string[]
  setValue: UseFormSetValue<FieldValues>
  setGoalExpectedEndDate: Dispatch<SetStateAction<string>>
  planStartDate?: React.MutableRefObject<string | undefined>
  goalId?: string
  userAvatar?: string
  userFirstName?: string
  userLastName?: string
  isStartDateBeforePlanStartDate?: boolean
  assigneeOptions?: Array<{ label: string; value: string }>
}

export const AppTaskFields: React.FC<AppTaskFieldsProps> = ({
  methods,
  task,
  logo,
  userId,
  clickedFields,
  advisorsData,
  handleClick,
  isViewMode,
  userName,
  noneEditableFields,
  setValue,
  setGoalExpectedEndDate,
  planStartDate,
  userAvatar,
  userFirstName,
  userLastName,
  isStartDateBeforePlanStartDate,
  assigneeOptions,
}) => {
  const user = useSelector((state: RootState) => state.user.user)
  const isAdvisor = user?.roles.includes('BSO_ADVISOR')
  const advisor = advisorsData.find((advisor) => advisor.value === user?.id)
  const shownUserName = isAdvisor ? advisor?.label : userName
  const creator =
    assigneeOptions?.find((creator) => creator.value === task?.createdBy)?.label || shownUserName
  return (
    <>
      <div className="flex flex-col gap-4">
        <AppTaskNameField
          methods={methods}
          clickedFields={clickedFields}
          handleClick={
            !noneEditableFields?.includes(TaskFormFields.name)
              ? handleClick
              : () => handleClick(TaskFormFields.name, false)
          }
        />
        <InformationGrid>
          <AppTaskGoalField
            setValue={setValue}
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.goalId)
                ? handleClick
                : () => handleClick(TaskFormFields.goalId, false)
            }
            setGoalExpectedEndDate={setGoalExpectedEndDate}
            planStartDate={planStartDate}
          />
          <AppTaskStatusField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.status)
                ? handleClick
                : () => handleClick(TaskFormFields.status, false)
            }
          />
          <AppHourEstimationField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.estimatedEffortInMinutes)
                ? handleClick
                : () => handleClick(TaskFormFields.estimatedEffortInMinutes, false)
            }
          />
          <AppTaskPriorityField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.taskPriority)
                ? handleClick
                : () => handleClick(TaskFormFields.taskPriority, false)
            }
          />
          <AppTaskAssigneeField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.assigneeId)
                ? handleClick
                : () => handleClick(TaskFormFields.assigneeId, false)
            }
            userAvatar={userAvatar}
          />
          <FieldLabel>Creator </FieldLabel>
          <div className="col-span-7 font-normal text-black-dark">
            <div className="flex justify-left gap-3 items-center">
              {task?.createdBy === 'Assistant' || task?.createdBy === 'GoTackle' ? (
                <AppAvatar src={Avatar} size={'sm'} type={'image'} shape={'Rounded'} />
              ) : (
                <AppAvatar
                  src={userAvatar}
                  size={'sm'}
                  type={'mixed'}
                  shape={'Rounded'}
                  text={
                    isAdvisor
                      ? getInitials({
                          firstName: advisor?.label ?? '',
                          lastName: advisor?.label ?? '',
                        })
                      : getInitials({
                          firstName: userFirstName ?? '',
                          lastName: userLastName ?? '',
                        })
                  }
                />
              )}
              <UserName>
                {isViewMode
                  ? !task?.createdBy || task?.createdBy === 'GoTackle'
                    ? 'Coach Logic'
                    : creator
                  : creator}
              </UserName>
            </div>
          </div>
          <AppTaskDateFields
            methods={methods}
            clickedFields={clickedFields}
            handleClickStartDate={
              !noneEditableFields?.includes(TaskFormFields.startDate)
                ? handleClick
                : () => handleClick(TaskFormFields.startDate, false)
            }
            handleClickExpectedEndDate={
              !noneEditableFields?.includes(TaskFormFields.expectedEndDate)
                ? handleClick
                : () => handleClick(TaskFormFields.expectedEndDate, false)
            }
            isStartDateBeforePlanStartDate={isStartDateBeforePlanStartDate}
          />

          <AppTaskCategoryField
            methods={methods}
            clickedFields={clickedFields}
            handleClick={
              !noneEditableFields?.includes(TaskFormFields.category)
                ? handleClick
                : () => handleClick(TaskFormFields.category, false)
            }
          />
        </InformationGrid>
      </div>
    </>
  )
}
