/* eslint-disable react-hooks/exhaustive-deps */
import { RootState } from 'App'
import {
  BusinessGoalDateFilter,
  BusinessGoalPriority,
  UserTask,
  UserTaskItemStatus,
  useGetUserTasksQuery,
} from '__generated__/api-types-and-hooks'
import { ITaskSummaryItem } from 'components/AppDashboard/interfaces'
import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getTenantId } from 'utils/getTenantId'

interface IUseGetUserTasksInput {
  filter?: BusinessGoalDateFilter
}

export const useGetUserTasks = (input?: IUseGetUserTasksInput) => {
  const tenantId = getTenantId()
  const clientId = useSelector((state: RootState) => state.clients.client.profile.id)
  const [userTasks, setUserTasks] = useState<UserTask[]>([])
  const {
    data,
    isLoading: isUserTasksLoading,
    refetch: refetchUserTasks,
  } = useGetUserTasksQuery(
    { input: { tenantId, userId: clientId, filter: { dateRange: input?.filter } } },
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (data && data.getUserTasks) {
      setUserTasks(data.getUserTasks as unknown as UserTask[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.getUserTasks])

  const filterTaskByStatus = (UserTasks: UserTask[], status: UserTaskItemStatus) => {
    const tasks = userTasks.filter((task) => task.status === status)
    return tasks.map((task) => ({
      ...task,
      priorityLabel: task.taskPriority?.split('_').join(' ').toLowerCase() ?? '',
      priorityStatus: task.taskPriority ?? BusinessGoalPriority.MediumPriority,
    }))
  }

  const todoTasks: ITaskSummaryItem[] = useMemo(
    () => filterTaskByStatus(userTasks, UserTaskItemStatus.Todo),
    [userTasks]
  )
  const inProgressTasks: ITaskSummaryItem[] = useMemo(
    () => filterTaskByStatus(userTasks, UserTaskItemStatus.InProgress),
    [userTasks]
  )
  const blockedTasks: ITaskSummaryItem[] = useMemo(
    () => filterTaskByStatus(userTasks, UserTaskItemStatus.Blocked),
    [userTasks]
  )
  const completedTasks: ITaskSummaryItem[] = useMemo(
    () => filterTaskByStatus(userTasks, UserTaskItemStatus.Done),
    [userTasks]
  )
  const uncompletedTasks: ITaskSummaryItem[] = useMemo(() => {
    const tasks = userTasks.filter((task) => task.status !== UserTaskItemStatus.Done)
    return tasks.map((task) => ({
      ...task,
      priorityLabel: task.taskPriority?.split('_').join(' ').toLowerCase() ?? '',
      priorityStatus: task.taskPriority!,
    }))
  }, [userTasks])

  return {
    userTasks,
    todoTasks,
    inProgressTasks,
    blockedTasks,
    completedTasks,
    uncompletedTasks,
    isUserTasksLoading,
    refetchUserTasks,
  }
}
