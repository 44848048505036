import React, { useEffect, useRef } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'

import { AppTextArea } from 'components/Common/AppFormFields/AppTextArea/AppTextArea.component'
import { TaskFormFields } from 'components/AppKanbanBoard/config'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import remarkMath from 'remark-math'

interface IAppTaskDescriptionFieldProps {
  methods: UseFormReturn
  clickedFields: { [key: string]: boolean }
  handleClick: (field: string, isHovered: boolean) => void
}

export const AppTaskDescriptionField: React.FC<IAppTaskDescriptionFieldProps> = ({
  methods,
  clickedFields,
  handleClick,
}) => {
  const descriptionWrapperRef = useRef<HTMLDivElement | null>(null)

  const { control } = methods

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        descriptionWrapperRef.current &&
        !descriptionWrapperRef.current.contains(event.target as Node)
      ) {
        handleClick(TaskFormFields.instructionForTask, false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [handleClick])

  return (
    <Controller
      name={TaskFormFields.instructionForTask}
      control={control}
      render={({ field }) => (
        <>
          {!clickedFields.instructionForTask ? (
            <div
              className="font-inter text-sm text-app-black-90  max-h-28 overflow-y-auto rounded-lg [&_a]:text-app-blue-600 [&_a]:underline hover:[&_a]:text-app-blue-800"
              onClick={() => handleClick(field.name, true)}
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw] as any}
                remarkPlugins={[remarkGfm, remarkMath]}
              >
                {field.value ? field.value : 'Type play description..'}
              </ReactMarkdown>
            </div>
          ) : (
            <div ref={descriptionWrapperRef}>
              <AppTextArea
                placeholder="Type play description..."
                rows={4}
                {...field}
                onChange={(e) => {
                  field.onChange(e)
                }}
              />
            </div>
          )}
        </>
      )}
    />
  )
}
